<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage User
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="2" lg="2" class="mt-3">
                  <v-select
                    :items="['Login', 'Not Login']"
                    label="Status Login"
                    v-model="StatusLoginSearch"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" lg="2" class="mt-3">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Active"
                    v-model="activeSearch"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" lg="3" class="mt-3">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-on:keyup.enter="SearchMember(search)"
                    v-model="search"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" lg="5" class="mt-2" style="text-align: end">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="getDataMember()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    color="green"
                    class="ma-2 white--text"
                    @click="checkDataButton('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="MemberList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">
                      {{ item.firstName + " " + item.lastName }}
                    </td>
                    <td class="header-table-css">
                      {{ item.user_Name }}
                    </td>
                    <td class="header-table-css">{{ item.department }}</td>
                    <td class="header-table-css">{{ item.position }}</td>
                    <td class="header-table-css">{{ item.roleName }}</td>
                    <td class="header-table-css">
                      <v-icon
                        v-if="item.isLogIn == false"
                        size="30"
                        style="color: lightgrey"
                        @click="ForceStatusLogin(item.user_ID)"
                        >{{ "mdi-circle" }}</v-icon
                      >
                      <v-icon
                        v-if="item.isLogIn == true"
                        size="30"
                        style="color: limegreen"
                        @click="ForceStatusLogin(item.user_ID)"
                        >{{ "mdi-circle" }}</v-icon
                      >
                    </td>
                    <td class="header-table-css">
                      <v-icon
                        v-if="item.isMultiLogin == false"
                        size="30"
                        style="color: lightgrey"
                        >{{ "mdi-circle" }}</v-icon
                      >
                      <v-icon
                        v-if="item.isMultiLogin == true"
                        size="30"
                        style="color: limegreen"
                        >{{ "mdi-circle" }}</v-icon
                      >
                    </td>

                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center;min-width: 150px">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="checkDataButton('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveUserByUserID(item.user_ID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>

                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="!editCheck" class="text-h5">Create User</span>
          <span v-if="editCheck" class="text-h5">Edit User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="User Name*"
                      v-model="userName"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10" md="10">
                    <v-text-field
                      label="Password*"
                      type="password"
                      id="password"
                      v-model="password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" class="mt-5">
                    <span>
                      <v-icon
                        v-show="flagPassword == true"
                        @click="TogglePassword()"
                        >mdi-eye-off</v-icon
                      >
                    </span>
                    <span>
                      <v-icon
                        v-show="flagPassword == false"
                        @click="TogglePassword()"
                        >mdi-eye</v-icon
                      >
                    </span>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="First Name*"
                      v-model="firstname"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Last Name*"
                      v-model="lastname"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Department"
                      append-icon="mdi-card-account-details-outline"
                      v-model="department"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Position"
                      append-icon="mdi-card-account-details-outline"
                      v-model="position"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="roleArray"
                      item-value="roleID"
                      item-text="shortName"
                      label="Role"
                      v-model="role"
                      required
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="['Multiple', 'Single']"
                      label="Multiple Login"
                      v-model="Multiple"
                      required
                      
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Note"
                      append-icon="mdi-note-text-outline"
                      v-model="note"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" md="3" style="text-align: center">
                <v-img
                  class="bg-white"
                  width="300"
                  style="border: 1px solid #000000"
                  :aspect-ratio="1"
                  :src="urlImg"
                  cover
                ></v-img>
                <v-file-input
                  label="Image"
                  accept="image/png, image/jpeg, image/bmp"
                  append-icon="mdi-camera"
                  v-model="image"
                  @change="Preview_image"
                ></v-file-input>
                <v-row style="text-align: left; margin-left: 10px">
                  <v-col cols="12" sm="3" md="3">
                    <v-switch
                      v-model="Switch"
                      hide-details
                      inset
                      :label="Switch ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="addUser()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog"  persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "ManageMember",
  components: {
    AlertComponent,
  },
  data() {
    return {
      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20],
      itemsDropdown: ["Male", "Female"],
      valueDropdown: [],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      role: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      addData: false,
      Switch: false,
      urlImg: null,
      MemberList: [],
      tenant: [],
      itemsAction: [{ title: "Edit" }, { title: "Delete" }],

      memberID: "",
      personID: "",
      firstname: "",
      lastname: "",
      department: "",
      position: "",
      gender: "",
      idcard: "",
      passport: "",
      phone: "",
      email: "",
      note: "",
      tenantArray: [],
      roleArray: [],

      flagPassword: false,
      userName: "",
      password: "",
      user_ID: "",

      startDate: "",
      endDate: "",
      menu: false,
      menu1: false,
      modal: false,
      menu2: false,
      editCheck: false,
      activeSearch: null,
      StatusLoginSearch: null,
      Multiple: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
        },
        {
          text: "Username",
          align: "center",
          sortable: false,
        },
        {
          text: "Department",
          align: "center",
          sortable: false,
        },
        {
          text: "Position",
          align: "center",
          sortable: false,
        },
        {
          text: "Role",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "CreateDate",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "StatusLogin",
          align: "center",
          sortable: false,
        },
        {
          text: "Multiple Login",
          align: "center",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  mounted() {
    this.getDataMember();
    this.getRole();
  },
  methods: {
    clearstartDate() {
      this.startDate = null;
    },
    clearendDate() {
      this.endDate = null;
    },
    Preview_image() {
      this.urlImg = URL.createObjectURL(this.image);
    },
    checkDataButton(type, data) {
      let that = this;
      if (type == "add") {
        that.editCheck = false;
        that.dialog = true;
        that.Switch = true;
        that.firstname = "";
        that.lastname = "";
        that.userName = "";
        that.password = "";
        that.department = "";
        that.position = "";
        that.role = "";
        that.Multiple = null;
        that.note = "";
      } else {
        that.getUserByUserID(data.user_ID);
      }
    },
    mapEdit(data) {
      let that = this;

      that.editCheck = true;
      that.dialog = true;
      that.firstname = data.firstName;
      that.lastname = data.lastName;

      that.userName = data.user_Name;
      that.password = data.user_PWD;
      that.department = data.department;
      that.position = data.position;
      that.note = data.note;
      that.user_ID = data.user_ID;
      that.role = data.role_ID;
      that.Multiple = data.isMultiLogin== true  ? 'Multiple' : 'Single';

      that.Switch = data.active == true ? data.active : false;
    },
    checkVakidates() {
      let that = this;
      if (
        that.firstname == "" ||
        that.lastname == "" ||
        that.userName == "" ||
        that.password == "" ||
        that.department == "" ||
        that.position == ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    TogglePassword() {
      let that = this;
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        that.flagPassword = false;
      } else {
        x.type = "password";
        that.flagPassword = true;
      }
    },

    getDataMember() {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: that.search == null ? "" : that.search,
        startDate: that.startDate,
        endDate: that.endDate,
        isLogin:
          that.StatusLoginSearch == "Login"
            ? true
            : that.StatusLoginSearch == null
            ? null
            : false,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}User/getUserList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.MemberList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    SearchMember(data) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: data,
        startDate: that.startDate,
        endDate: that.endDate,
        isLogin:
          that.StatusLoginSearch == "Active"
            ? true
            : that.StatusLoginSearch == null
            ? null
            : false,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}User/getUserList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.MemberList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },
    getUserByUserID(id) {
      let that = this;
      let temp = {
        User_ID: id,
      };
      axios
        .post(`${that.url}User/getUserByUserID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.mapEdit(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    inActiveUserByUserID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        User_ID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}User/inActiveUserByUserID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getDataMember();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              Swal.fire("Error!", error.message);
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    addUser() {
      let that = this;
      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      that.LoadingDialog = true;

      if (!that.editCheck) {
        let temp = {
          FirstName: that.firstname,
          LastName: that.lastname,
          User_Name: that.userName,
          User_PWD: that.password,
          Department: that.department,
          Position: that.position,
          RoleID: that.role,
          note: that.note,
          active: that.Switch,
          isMultiLogin:  that.Multiple == 'Multiple' ? true : false,
        };
        axios
          .post(`${that.url}User/addUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getDataMember();
            that.dialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            Swal.fire("Error!", error.message);
          });
      } else {
        let temp = {
          User_ID: that.user_ID,
          FirstName: that.firstname,
          LastName: that.lastname,
          User_Name: that.userName,
          User_PWD: that.password,
          Department: that.department,
          Position: that.position,
          RoleID: that.role,
          note: that.note,
          active: that.Switch,
          isMultiLogin:  that.Multiple == 'Multiple' ? true : false,
        };
        axios
          .post(`${that.url}User/editUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getDataMember();
            that.dialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            Swal.fire("Error!", error.message);
          });
      }

    
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImg = URL.createObjectURL(file);
    },
    getImage() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    },

    ChangePage(value) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: that.itemsPerPage,
        search: that.search == null ? "" : that.search,
        startDate: that.startDate,
        endDate: that.endDate,
        isLogin:
          that.StatusLoginSearch == "Active"
            ? true
            : that.StatusLoginSearch == null
            ? null
            : false,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}User/getUserList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.MemberList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    ChangePerPage(value) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: that.search == null ? "" : that.search,
        startDate: that.startDate,
        endDate: that.endDate,
        isLogin:
          that.StatusLoginSearch == "Active"
            ? true
            : that.StatusLoginSearch == null
            ? null
            : false,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}User/getUserList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.MemberList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        DateData.getMonth() +
        1 +
        "/" +
        DateData.getDate() +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    getRole() {
      let that = this;
      axios
        .get(`${that.url}User/getRole`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.roleArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    ForceStatusLogin(id) {
      let that = this;
      let temp = {
        userID: id,
      };
      Swal.fire({
        text: "ต้องการบังคับให้ User นี้ออกจากระบบหรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "limegreen",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Login/updateStatusActive`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                that.getDataMember();
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
            })
            .catch(function (error) {
              Swal.fire("Error!", error.message);
            });
        }
      });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
