<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
        Search Car Information
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-5">
                  <v-row>
                    <v-col cols="10" sm="12" md="10" lg="10">
                      <v-row>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-text-field
                            label="หมายเลขบัตร"
                            append-icon="mdi-database-search-outline"
                            v-model="logEntry"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-text-field
                            label="ทะเบียนรถ"
                            append-icon="mdi-database-search-outline"
                            v-model="carNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-select
                            :items="['Car', 'Motorcycle']"
                            label="ประเภทรถ"
                            v-model="vehicleType"
                            required
                            clearable
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-text-field
                            label="รหัสสมาชิก"
                            append-icon="mdi-database-search-outline"
                            v-model="ticketNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-menu
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="entryDate"
                                label="เวลาเข้า"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="entryDate"
                              @change="menuStartDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-menu
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="exitDate"
                                label="เวลาออก"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="exitDate"
                              @change="menuEndDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-text-field
                            label="ตู้ทำรายการ"
                            append-icon="mdi-database-search-outline"
                            v-model="terminalEntry"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-select
                            :items="['ยังอยู่ในระบบ', 'ออกจากระบบ']"
                            label="Exist Status"
                            v-model="statusLog"
                            required
                            clearable
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" sm="12" md="2" lg="2">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                          style="text-align: end"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-btn
                              :color="'blue'"
                              class="ma-2 white--text"
                              :elevation="hover ? 10 : 2"
                              :class="{ 'on-hover': hover }"
                              @click="getDataTransactionCarpark()"
                            >
                              Search

                              <v-icon right> mdi-magnify </v-icon>
                            </v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="DataList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-center-css">
                      {{ no_run + (index + 1) }}
                    </td>
                    <td class="header-table-center-css">{{ item.logEntry }}</td>
                    <td class="header-table-center-css">{{ item.carNo }}</td>
                    <td class="header-table-center-css">
                      {{ ChangeFormatDate(item.entryDate) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ ChangeFormatDate(item.exitDate) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.vehicleType == 0 ? "Car" : "Motorcycle" }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.ticketNo }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.terminalEntry }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.terminalExit }}
                    </td>

                    <td style="text-align: center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="blue"
                        @click="OpenDialog('view', item.logEntry)"
                      >
                        <v-icon dark> mdi mdi-eye </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-model="TransactionCarparkDialog"
      persistent
      max-width="700px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Transaction Carpark</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="ลำดับรายการ"
                  v-model="DataListCarparkID.logID"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="หมายเลขบัตร"
                  v-model="DataListCarparkID.ticketNo"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="ทะเบียนรถ"
                  v-model="DataListCarparkID.carNo"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="ประเภทรถ"
                  v-model="DataListCarparkID.vehicleType"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  label="เวลาทำรายการ"
                  v-model="DataListCarparkID.logDate"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="ตู้"
                  v-model="DataListCarparkID.terminalID"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  label="เวลาเข้า"
                  v-model="DataListCarparkID.entryDate"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="ตู้"
                  v-model="DataListCarparkID.terminalID"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="เวลาจอด"
                  v-model="DataListCarparkID.parkTime"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Rate"
                  v-model="DataListCarparkID.rate"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="ประเภทรายการ"
                  v-model="DataListCarparkID.operation"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="รหัสสมาชิก"
                  v-model="DataListCarparkID.memberCode"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="ผู้ทำรายการ"
                  v-model="DataListCarparkID.createByEntry"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="ค่าปรับบัตรหาย"
                  v-model="DataListCarparkID.lostCardPaid"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="ค่าปรับค้างคืน"
                  v-model="DataListCarparkID.overnightPaid"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="ค่าบริการ"
                  v-model="DataListCarparkID.totalCash"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6"> </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  label="รวมทั้งสิ้น"
                  v-model="DataListCarparkID.total"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6"> </v-col>

              <v-row
                style="text-align: -webkit-center"
                v-if="DriverImgIn != ''"
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="text-align: left; color: black;padding-left: 70px;"
                >
                  <label>ภาพรถขาเข้า</label>
                </v-col>
                <v-col cols="1" sm="1" md="1"> </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-img height="250" width="250" :src="DriverImgIn"></v-img>
                </v-col>
                <v-col cols="5" sm="5" md="5">
                  <v-img
                    height="250"
                    width="250"
                    :src="LicensePlateImgIn"
                  ></v-img>
                </v-col>
                <v-col cols="1" sm="1" md="1"> </v-col>
              </v-row>

              <v-row
                style="text-align: -webkit-center"
                v-if="DriverImgOut != ''"
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="text-align: left; color: black;padding-left: 70px;"
                >
                  <label>ภาพรถขาออก</label>
                </v-col>
                <v-col cols="1" sm="1" md="1"> </v-col>
                <v-col cols="5" sm="5" md="5" v-if="DriverImgOut != ''">
                  <v-img height="250" width="250" :src="DriverImgOut"></v-img>
                </v-col>
                <v-col cols="5" sm="5" md="5" v-if="LicensePlateImgOut != ''">
                  <v-img
                    height="250"
                    width="250"
                    :src="LicensePlateImgOut"
                  ></v-img>
                </v-col>
                <v-col cols="1" sm="1" md="1"> </v-col>
              </v-row>

              <!-- <v-card class="mx-auto">
                <v-col cols="6" sm="6" md="6" v-if="DriverImgOut != ''">
                <v-img height="200" width="200" :src="DriverImgOut"></v-img>
              </v-col>
              <v-col cols="6" sm="6" md="6" v-if="LicensePlateImgOut != ''">
                <v-img
                  height="200"
                  width="200"
                  :src="LicensePlateImgOut"
                ></v-img>
              </v-col>
              </v-card> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: center; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="TransactionCarparkDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog"  persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "SearchCarInformation",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      DataList: [],
      DataListCarparkID: [],
      DataListImgID: [],

      DriverImgIn: "",
      LicensePlateImgIn: "",
      DriverImgOut: "",
      LicensePlateImgOut: "",

      search: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      TransactionCarparkDialog: false,
      EditStatus: false,

      // Add Edit

      Status: true,

      searchBy: "",
      listType: "",
      text: "",
      vmsKiosk: "",
      stamp: "",

      logEntry: "",
      carNo: "",
      entryDate: "",
      exitDate: "",
      vehicleType: "",
      ticketNo: "",
      terminalEntry: "",
      statusLog: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Log in",
          align: "center",
          sortable: false,
        },
        {
          text: "ทะเบียนรถ",
          align: "center",
          sortable: false,
        },
        {
          text: "เวลาเข้า",
          align: "center",
          sortable: false,
        },
        {
          text: "เวลาออก",
          align: "center",
          sortable: false,
        },
        {
          text: "ประเภทรถ",
          align: "center",
          sortable: false,
        },
        {
          text: "รหัสสมาขิก",
          align: "center",
          sortable: false,
        },
        {
          text: "ตู้ทำรายการเข้า",
          align: "center",
          sortable: false,
        },
        {
          text: "ตู้ทำรายการออก",
          align: "center",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    let self = this;
    self.getDataTransactionCarpark();
  },

  methods: {
    getDataTransactionCarpark() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        logEntry: self.logEntry == "" ? null : self.logEntry,
        carNo: self.carNo == "" ? null : self.carNo,
        entryDate: self.entryDate == "" ? null : self.entryDate,
        exitDate: self.exitDate == "" ? null : self.exitDate,
        vehicleType:
          self.vehicleType == "" || self.vehicleType == null
            ? null
            : self.vehicleType == "Car"
            ? 0
            : 1,
        ticketNo: self.ticketNo == "" ? null : self.ticketNo,
        terminalEntry: self.terminalEntry == "" ? null : self.terminalEntry,
        statusLog:
          self.statusLog == "" || self.statusLog == null
            ? null
            : self.statusLog == "ยังอยู่ในระบบ"
            ? 0
            : 1,
      };

      axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },
    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var month = DateData.getMonth() + 1;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        DateData.getDate() +
        "/" +
        month +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenDialog(module, data) {
      let self = this;
      self.getDataTransactionCarparkByLogID(data);
      // self.BindingEditData(data);
    },

    getDataTransactionCarparkByLogID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        logid: id,
      };
      axios
        .post(
          `${that.url}TransactionCarpark/getDataTransactionCarparkByLogID`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.DataListCarparkID = response.data.data.transaction;
            that.DataListImgID = response.data.data.picture;
            that.BindingEditData(that.DataListCarparkID, that.DataListImgID);

            // that.get();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
      that.LoadingDialog = false;
    },
    BindingEditData(transaction, picture) {
      let that = this;

      that.DriverImgIn = "";
      that.LicensePlateImgIn = "";
      that.DriverImgOut = "";
      that.LicensePlateImgOut = "";

      that.DataListCarparkID.vehicleType =
        transaction.vehicleType == "0" ? "Car" : "Motorcycle";
      that.DataListCarparkID.logDate = that.ChangeFormatDate(
        transaction.logDate
      );
      that.DataListCarparkID.entryDate = that.ChangeFormatDate(
        transaction.entryDate
      );
      that.DataListCarparkID.parkTime =
        transaction.parkTime == ":" ? "" : transaction.parkTime;

      // that.DataListImgID[0].trn_Driver_Img =  picture[0].trn_Driver_Img;
      // that.DataListImgID[0].trn_License_Plate_Img = picture[0].trn_License_Plate_Img;

      if (picture.pictureEntry != null) {
        that.DriverImgIn =
          "data:image/jpg;base64," + picture.pictureEntry.trn_Driver_Img;
        that.LicensePlateImgIn =
          "data:image/jpg;base64," + picture.pictureEntry.trn_License_Plate_Img;
      }
      if (picture.pictureExit != null) {
        that.DriverImgOut =
          "data:image/jpg;base64," + picture.pictureExit.trn_Driver_Img;
        that.LicensePlateImgOut =
          "data:image/jpg;base64," + picture.pictureExit.trn_License_Plate_Img;
      }

      // "data:image/jpg;base64," + response.data.qrImage;

      that.TransactionCarparkDialog = true;
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-center-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
