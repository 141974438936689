<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Open Bar
        </div>
        <v-card-text>
          <div>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-card
                  elevation="10"
                  style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
                >
                  <v-card-title
                    class="text-h5"
                    style="display: flex; justify-content: space-around"
                  >
                    Open Bar (เปิดไม้กั้นฉุกเฉิน)
                  </v-card-title>
                  <v-row style="display: flex; justify-content: space-around">
                    <v-col cols="3" sm="3" md="3" lg="3">
                      <v-img
                        class="bg-white"
                        style="background-size: contain"
                        :aspect-ratio="1"
                        :src="imageCar"
                      ></v-img>
                    </v-col>
                  </v-row>

                  <v-row style="display: flex; justify-content: space-around">
                    <v-col cols="12" sm="8" md="8">
                      <v-autocomplete
                        :items="terminalList"
                        item-value="terminal_ID"
                        item-text="terminal_Name"
                        label="Terminal"
                        clearable
                        outlined
                        v-model="terminal"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-autocomplete
                        :items="openBarIssueList"
                        item-value="issue_ID"
                        item-text="issue_Name"
                        label="Issue Type"
                        clearable
                        outlined
                        v-model="openBarIssue"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        v-model="LicensePlate"
                        label="License Plate"
                        clearable
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      style="
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 5%;
                      "
                    >
                      <v-btn
                        class="ma-2"
                        min-width="300"
                        min-height="50"
                        :loading="loading4"
                        :disabled="loading4"
                        color="info"
                        @click="addOpenBar()"
                      >
                        Open Bar
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-card
                  elevation="10"
                  style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
                >
                  <v-card-title class="text-h5">
                    Terminal Handheld OpenBar
                  </v-card-title>
                  <v-data-table
                    :headers="headersHandheld"
                    :items="HandheldList"
                    hide-default-footer
                    style="color: #332f2fde"
                    :items-per-page="itemsPerPageHandheld"
                    :page.sync="pageHandheld"
                    single-line
                    hide-details
                  >
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td class="header-table-css">
                          {{ no_runHandheld + (index + 1) }}
                        </td>
                        <td class="header-table-css">
                          {{ item.id }}
                        </td>
                        <td class="header-table-css">
                          {{ item.terminal_name }}
                        </td>
                        <td class="header-table-css">
                          {{ item.open_Bar }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <br />
                  <v-row class="mt-5">
                    <v-col cols="12" md="2"></v-col>
                    <v-col cols="12" md="7">
                      <v-pagination
                        v-model="pageHandheld"
                        :total-visible="20"
                        :length="pageCountHandheld"
                        @input="ChangePageHandheld(pageHandheld)"
                      ></v-pagination>
                    </v-col>
                    <v-col cols="12" md="1"></v-col>
                    <v-col cols="12" md="2">
                      <v-autocomplete
                        dense
                        solo
                        label="10/page"
                        v-model="itemsPerPageHandheld"
                        @input="ChangePerPageHandheld(itemsPerPageHandheld)"
                        :items="itemsHandheld"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-card-title class="text-h5"> Transaction OpenBar </v-card-title>
              <v-data-table
                :headers="headersOpenBar"
                :items="OpenBarList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPageOpenBar"
                :page.sync="pageOpenBar"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">
                      {{ no_runOpenBar + (index + 1) }}
                    </td>
                    <td class="header-table-css">
                      {{ item.trn_LogID }}
                    </td>
                    <td class="header-table-css">
                      {{ ChangeFormatDateNoTime(item.trn_Date) }}
                    </td>
                    <td class="header-table-css">
                      {{ item.trn_Terminal_Name }}
                    </td>
                    <td class="header-table-css">
                      {{ item.trn_User_ID }}
                    </td>
                    <td class="header-table-css">
                      {{ item.trn_Car_ID }}
                    </td>
                    <!-- <td class="header-table-css">
                      {{ item.reason_ID }}
                    </td> -->
                    <td class="header-table-css">
                      {{ item.reason }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="pageOpenBar"
                    :total-visible="20"
                    :length="pageCountOpenBar"
                    @input="ChangePageOpenBar(pageOpenBar)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPageOpenBar"
                    @input="ChangePerPageOpenBar(itemsPerPageOpenBar)"
                    :items="itemsOpenBar"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "OpenBar",
  components: {},
  data() {
    return {
      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20],
      itemsHandheld: [10, 15, 20],
      itemsOpenBar: [10, 15, 20],
      no_run: null,
      no_runHandheld: null,
      no_runOpenBar: null,
      pageCount: 0,
      pageCountHandheld: 0,
      pageCountOpenBar: 0,
      page: 1,
      pageHandheld: 1,
      pageOpenBar: 1,
      itemsPerPage: 10,
      itemsPerPageHandheld: 10,
      itemsPerPageOpenBar: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      HandheldList: [],
      OpenBarList: [],
      RateList: [],
      ProfileList: [],
      MasterOpenBar: [],
      TerminalHandheld: [],
      TransactionOpenBar: [],
      terminalList: [],
      terminal: "",
      openBarIssueList: [],
      openBarIssue: "",
      search: "",
      searchHandheld: "",
      searchOpenBar: "",
      LicensePlate: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      RateDialog: false,
      EditStatus: false,

      // Add Edit
      rateID: 0,
      templateName: "",
      templateNameTH: "",
      templateNameEng: "",

      rateNameTH: "",
      Status: true,

      invoiceStatus: false,
      lumpSumStatus: false,
      freeStatus: false,
      periodStatus: false,
      amount: 0,
      templateTime: 0,

      templateID: 0,
      startPeriod: null,
      endPeriod: null,
      menu2: false,
      modal_startPeriod: false,
      modal_endPeriod: false,

      imageCar: require("@/assets/barrier-and-car.svg"),
      nameTemplateTH: "",
      nameTemplateEng: "",
      profileIDFirst: 0,
      profileIDSecond: 0,
      conjunctionID: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Template Name TH",
          align: "left",
          sortable: false,
        },
        {
          text: "Template Name Eng",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersHandheld() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "ID",
          align: "left",
          sortable: false,
        },
        {
          text: "Terminal Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Open Bar",
          align: "left",
          sortable: false,
        },
      ];
    },
    headersOpenBar() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Trn LogID",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Terminal Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn User ID",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn Car ID",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Reason ID",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Reason",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Direction",
        //   align: "center",
        //   sortable: false,
        // },
      ];
    },
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // setTimeout(() => (this[l] = false), 3000);
      if (this.LoadingDialog == false) {
        this.loader = null;
      }
    },
  },

  mounted() {
    let self = this;

    self.getDataMasterOpenBar();
    self.getTerminalHandheldOpenBar();
    self.getTransacOpenBar();
  },

  methods: {
    getDataList() {
      let self = this;
      self.getTerminalHandheldOpenBar();
      self.getTransacOpenBar();
    },

    getTerminalHandheldOpenBar() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageHandheld,
        perpage: self.itemsPerPageHandheld,
        search: self.searchHandheld == null ? "" : self.searchHandheld,
      };
      axios
        .post(`${self.url}OpenBar/getTerminalHandheldOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.HandheldList = response.data.data.listData;
            self.pageCountHandheld = response.data.data.count;
            self.no_runHandheld =
              (self.pageHandheld - 1) * self.itemsPerPageHandheld;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },
    getTransacOpenBar() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageOpenBar,
        perpage: self.itemsPerPageOpenBar,
        search: self.searchOpenBar == null ? "" : self.searchOpenBar,
      };
      axios
        .post(`${self.url}OpenBar/getTransacOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.OpenBarList = response.data.data.listData;
            self.pageCountOpenBar = response.data.data.count;
            self.no_runOpenBar =
              (self.pageOpenBar - 1) * self.itemsPerPageOpenBar;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    getRateTemplateByTemplateID() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        templateID: self.templateID,
      };

      axios
        .post(`${self.url}Rate/getRateTemplateByTemplateID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.ProfileList = response.data.data;
            self.BindingEditData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    getDataMasterOpenBar() {
      let self = this;
      self.LoadingDialog = true;

      axios
        .get(`${self.url}OpenBar/getDataMasterOpenBar`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MasterOpenBar = response.data.data;
            self.openBarIssueList = response.data.data.openBarIssue;
            self.terminalList = response.data.data.terminal;
            //       TerminalHandheld: [],
            // TransactionOpenBar: [],
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },
    ChangePageOpenBar(value) {
      let self = this;
      self.LoadingDialog = true;
      self.pageOpenBar = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPageOpenBar,
        search: self.searchOpenBar == null ? "" : self.searchOpenBar,
      };
      axios
        .post(`${self.url}OpenBar/getTransacOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.OpenBarList = response.data.data.listData;
            self.pageCountOpenBar = response.data.data.count;
            self.no_runOpenBar =
              (self.pageOpenBar - 1) * self.itemsPerPageOpenBar;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPageOpenBar(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageOpenBar,
        perpage: value,
        search: self.searchOpenBar == null ? "" : self.searchOpenBar,
      };
      axios
        .post(`${self.url}OpenBar/getTransacOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.OpenBarList = response.data.data.listData;
            self.pageCountOpenBar = response.data.data.count;
            self.no_runOpenBar =
              (self.pageOpenBar - 1) * self.itemsPerPageOpenBar;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePageHandheld(value) {
      let self = this;
      self.LoadingDialog = true;
      self.pageHandheld = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPageHandheld,
        search: self.searchHandheld == null ? "" : self.searchHandheld,
      };
      axios
        .post(`${self.url}OpenBar/getTerminalHandheldOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.HandheldList = response.data.data.listData;
            self.pageCountHandheld = response.data.data.count;
            self.no_runHandheld =
              (self.pageHandheld - 1) * self.itemsPerPageHandheld;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPageHandheld(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageHandheld,
        perpage: value,
        search: self.searchHandheld == null ? "" : self.searchHandheld,
      };
      axios
        .post(`${self.url}OpenBar/getTerminalHandheldOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.HandheldList = response.data.data.listData;
            self.pageCountHandheld = response.data.data.count;
            self.no_runHandheld =
              (self.pageHandheld - 1) * self.itemsPerPageHandheld;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    SearchRate(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var month = DateData.getMonth() + 1;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        DateData.getDate() +
        "/" +
        month +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;

      if (
        that.LicensePlate == "" ||
        that.LicensePlate == null ||
        that.terminal == "" ||
        that.terminal == null ||
        that.openBarIssue == 0 ||
        that.openBarIssue == null
      ) {
        return true;
      } else {
        return false;
      }
    },

    addOpenBar() {
      let that = this;
      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      that.LoadingDialog = true;
      that.loader = "loading4";

      let Terminal_name = that.terminalList.find(
        (x) => x.terminal_ID == that.terminal
      ).terminal_Name;
      let Reason = that.openBarIssueList.find(
        (x) => x.issue_ID == that.openBarIssue
      ).issue_Name;

      let temp = {
        terminalId: that.terminal,
        licensePlate: that.LicensePlate,
        reason: Reason,

        reasonID: that.openBarIssue,
        terminalName: Terminal_name,
      };
      axios
        .post(`${that.url}OpenBar/addOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });

            that.terminal = "";
            that.LicensePlate = "";
            that.openBarIssue = "";
            that.loader = null;
            that.loading4 = false;
            that.getDataList();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // that.getRateList();
          that.RateDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    GetColor(value) {
      if (value == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenRateDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        // self.amount = 0;
        // self.templateName = "";
        // self.templateTime = 0;
        // self.invoiceStatus = false;
        // self.freeStatus = false;
        // self.Status = true;
        // self.periodStatus = false;
        // self.lumpSumStatus = false;
        //
        self.nameTemplateTH = "";
        self.nameTemplateEng = "";
        self.profileIDFirst = 0;
        self.profileIDSecond = 0;
        self.conjunctionID = 0;
        self.Status = true;
        self.RateDialog = true;
      } else {
        // self.BindingEditData(data);
        self.templateID = data.templateID;
        self.getRateTemplateByTemplateID();
      }
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.RateDialog = true;

      self.templateID = data.templateID;
      self.nameTemplateTH = data.nameTemplateTH;
      self.nameTemplateEng = data.nameTemplateEng;
      self.profileIDFirst = data.profileIDFirst;
      self.profileIDSecond = data.profileIDSecond;
      self.conjunctionID = data.conjunctionID;
      self.Status = data.active;
    },

    inActiveRateByRateID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        rateID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Role ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Rate/inActiveRateByRateID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getRateList();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              Swal.fire("Error!", error.message);
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    ChangeFormatDateNoTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
