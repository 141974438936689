<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card variant="outlined">
                <v-card-title style="font-weight: bolder"
                  >Welcome back
                  <span class="ml-5" style="color: #22577a">{{
                    username
                  }}</span>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="3">
              <v-card color="#385F73" dark>
                <v-card-title class="text-h5">
                  Member <v-icon class="ml-3">mdi-account-multiple</v-icon>
                </v-card-title>
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Active :
                      <span style="font-size: large; color: white">{{
                        TotalNumberDataDashboard?.memberActive
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Not Active :
                      <span style="font-size: large; color: white">{{
                        TotalNumberDataDashboard?.memberNotActive
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card color="#FEF5E5" dark>
                <v-card-title class="text-h5" style="color: #ffae1f">
                  Tenant
                  <v-icon class="ml-3" color="#FFAE1F"
                    >mdi-briefcase-account</v-icon
                  >
                </v-card-title>
                <v-card-subtitle style="color: #ffae1f">
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Active :
                      <span style="font-size: large; color: #ffae1f">{{
                        TotalNumberDataDashboard?.tenantActive
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Not Active :
                      <span style="font-size: large; color: #ffae1f">{{
                        TotalNumberDataDashboard?.tenantNotActive
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card color="#FDEDE8" dark>
                <v-card-title class="text-h5" style="color: #fa896b">
                  User
                  <v-icon class="ml-3" color="#FA896B">mdi-account-cog</v-icon>
                </v-card-title>
                <v-card-subtitle style="color: #fa896b">
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Active :
                      <span style="font-size: large; color: #fa896b">{{
                        TotalNumberDataDashboard?.userActive
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Not Active :
                      <span style="font-size: large; color: #fa896b">{{
                        TotalNumberDataDashboard?.userNoActive
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3"> </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div>
        <v-row style="display: flex">
          <v-col cols="6" md="6" sm="6">
            <v-card >
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card>
              <apexchart
                type="donut"
                height="500"
                :options="chartOptionsdonut"
                :series="seriesdonut"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- <div class="mb-3">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-card class="ml-4 mr-4" color="#5D87FF" dark>
              <v-card-title class="text-h5"> List </v-card-title>
              <v-card-subtitle>1234</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
    </v-card>

    <v-dialog v-model="LoadingDialog"  persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      show: false,
      items: [10, 15, 20],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      TotalNumberDataDashboard: [],
      username: localStorage.getItem("Username"),
      series: [
        {
          name: "Active",
          data: [1, 1, 1],
        },
        {
          name: "Not Active",
          data: [1, 1, 1],
        },
      ],

      chartOptionsdonut: {
        labels: ['Apple', 'Mango', 'Orange', 'Watermelon']
      },
      seriesdonut: [44, 55, 41, 17, 15],


      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Member",
            "Tenant",
            "User",
          ],
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  },

  computed: {},

  watch: {},
  mounted() {
    let that = this;
    that.getTotalNumberDataDashboard();
  },
  methods: {
    getTotalNumberDataDashboard() {
      let that = this;
      axios
        .get(`${that.url}Dashboard/getTotalNumberDataDashboard`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TotalNumberDataDashboard = response.data.data;

            that.series.forEach((element , index) => {
              if(index == 0) {
                element.data[0] = that.TotalNumberDataDashboard.memberActive;
              element.data[1] = that.TotalNumberDataDashboard.tenantActive;
              element.data[2] = that.TotalNumberDataDashboard.userActive;
              }
              if(index == 1) {
                element.data[0] = that.TotalNumberDataDashboard.memberNotActive;
              element.data[1] = that.TotalNumberDataDashboard.tenantNotActive;
              element.data[2] = that.TotalNumberDataDashboard.userNoActive;
              }

              
            });

            that.show = true;
            
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          Swal.fire("Error!", error.message);
          // eventBus.$emit('logout');
        });
    },

    logout() {
      eventBus.$emit("logout");
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
