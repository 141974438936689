<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Spending Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="3" class="mt-4">
                  <v-select
                    dense
                    outlined
                    clearable
                    label="Tenant"
                    :items="TenantList"
                    v-model="TenantID"
                    item-text="tenantName"
                    item-value="tenantID"
                    menu-props="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" class="mt-4">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="mt-4">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="mt-3">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="SearchDataSpending()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    color="green"
                    class="ma-2 white--text"
                    @click="OpenAddDataDialog()"
                  >
                    Create
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="SpendingList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="header-table-css">{{ item.spendingId }}</td>
                    <td class="header-table-css">{{ item.spendingDetail }}</td>
                    <td class="header-table-css">{{ numberWithCommas(item.minAmount) }}</td>
                    <td class="header-table-css">{{ numberWithCommas(item.maxAmount) }}</td>
                    <td class="header-table-css">{{ GetTenantName(item.tenantId) }}</td>
                    <td class="header-table-css">{{ item.rateCode }}</td>
                    <td style="padding-bottom: 5px" align="center">
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 130px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn class="mx-2" fab dark small color="orange" @click="getDataSpendingBySpendingID(item.spendingId)">
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn class="mx-2" fab dark small color="red">
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="AddDataDialog" persistent width="870">
      <v-card>
        <v-card-title>
          <p>Add Spending</p>
          <v-spacer></v-spacer>
          <v-btn @click="CloseAddDataDialog()" icon>
            <img src="@/assets/close.png" width="15" height="15" />
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-5" style="margin-top: -2%;">
          <v-col col="12" md="8">
            <p class="Title">Spending Detail</p>
            <v-textarea dense outlined v-model="AddSpendingDetail"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="4">
            <p class="Title">Min Amount</p>
            <v-text-field dense outlined type="number" v-model="AddMinAmount"></v-text-field>
          </v-col>
          <v-col col="12" md="4">
            <p class="Title">Max Amount</p>
            <v-text-field dense outlined type="number" v-model="AddMaxAmount"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="7">
            <p class="Title">Tenant</p>
            <v-select
              dense
              outlined
              v-model="AddTenant"
              @change="GetDataRateCodeFromTenant(AddTenant)"
              :items="TenantList"
              item-text="tenantName"
              item-value="tenantID"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="4">
            <p class="Title">Rate Code</p>
            <v-text-field
              dense
              v-model="AddRateCode"
              outlined
              disabled
            ></v-text-field>
          </v-col>
          <v-col col="12" md="8">
            <p class="Title">Rate Detail</p>
            <v-select
              dense
              outlined
              v-model="AddRateCode"
              :items="RateCodeList"
              item-text="template_Name"
              item-value="rate"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col md="6" col="12">
            <p class="Title">Status <span style="color: red">*</span></p>
            <v-radio-group row v-model="AddStatus">
              <v-radio label="Active" value="1"></v-radio>
              <v-radio label="InActive" value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%;margin-left:3%;"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9;"
            max-height="35"
            text
            @click="CloseAddDataDialog()"
            >Cancel</v-btn
          >
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddDataSpending()"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="EditDataDialog" persistent width="870">
      <v-card>
        <v-card-title>
          <p>Edit Spending</p>
          <v-spacer></v-spacer>
          <v-btn @click="CloseEditDataDialog()" icon>
            <img src="@/assets/close.png" width="15" height="15" />
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-5" style="margin-top: -2%;">
          <v-col col="12" md="8">
            <p class="Title">Spending Detail</p>
            <v-textarea dense outlined v-model="EditSpendingDetail"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="4">
            <p class="Title">Min Amount</p>
            <v-text-field dense outlined type="number" v-model="EditMinAmount"></v-text-field>
          </v-col>
          <v-col col="12" md="4">
            <p class="Title">Max Amount</p>
            <v-text-field dense outlined type="number" v-model="EditMaxAmount"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="7">
            <p class="Title">Tenant</p>
            <v-select
              dense
              outlined
              v-model="EditTenant"
              @change="GetDataRateCodeFromTenant(EditTenant)"
              :items="TenantList"
              item-text="tenantName"
              item-value="tenantID"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -4%;">
          <v-col col="12" md="4">
            <p class="Title">Rate Code</p>
            <v-text-field
              dense
              v-model="EditRateCode"
              outlined
              disabled
            ></v-text-field>
          </v-col>
          <v-col col="12" md="8">
            <p class="Title">Rate Detail</p>
            <v-select
              dense
              outlined
              v-model="EditRateCode"
              :items="RateCodeList"
              item-text="template_Name"
              item-value="rate"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col md="6" col="12">
            <p class="Title">Status <span style="color: red">*</span></p>
            <v-radio-group row v-model="EditStatus">
              <v-radio label="Active" value="1"></v-radio>
              <v-radio label="InActive" value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%;margin-left:3%;"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9;"
            max-height="35"
            text
            @click="CloseEditDataDialog()"
            >Cancel</v-btn
          >
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateDataSpending()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog"  persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageSpending",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20],
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      AddDataDialog: false,
      EditDataDialog: false,

      SpendingList: [],
      TenantList: [],
      RateCodeList: [],
      TenantID: null,
      activeSearch: null,
      
      AddSpendingDetail: "",
      AddTenant: null,
      AddMinAmount: null,
      AddMaxAmount: null,
      AddRateCode: null,
      AddStatus: null,

      SpendingID: null,
      EditSpendingDetail: "",
      EditTenant: null,
      EditMinAmount: null,
      EditMaxAmount: null,
      EditRateCode: null,
      EditStatus: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          sortable: false,
          align: "left",
        },
        {
          text: "SpendingDetail",
          align: "left",
          sortable: false,
        },
        {
          text: "MinAmount",
          align: "left",
          sortable: false,
        },
        {
          text: "MaxAmount",
          align: "left",
          sortable: false,
        },
        {
          text: "TenantID",
          align: "left",
          sortable: false,
        },
        {
          text: "RateCode",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {},

  async mounted() {
    let self = this;
    await self.getDataSpendingList();
    await self.GetDataTenantInRedemption();
  },

  methods: {
    async GetDataTenantInRedemption() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataTenantInRedemption`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TenantList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    async GetDataRateCodeFromTenant(data) {
      let self = this;
      self.EditRateCode = null;
      let temp = {
        tenantID: data
      };
      await axios
        .post(`${self.url}Spending/getDataRateCodeByTenantID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateCodeList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    async getDataSpendingList() {
      let self = this;
      let temp = {
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
        search: self.search,
        page: 1,
        perpage: 10,
      };
      self.LoadingDialog = true;
      await axios
        .post(`${self.url}Spending/getDataSpendingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.SpendingList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: 10,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Spending/getDataSpendingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.SpendingList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Spending/getDataSpendingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.SpendingList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    SearchDataSpending() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: 10,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Spending/getDataSpendingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.SpendingList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.page = 1;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    GetColor(value) {
      if (value == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    GetTenantName(value) {
      let NameTenant = "";
      
      if(this.TenantList.find(a => a.tenantID == value) != null){
        NameTenant = this.TenantList.find(a => a.tenantID == value).tenantName;
      }
      return NameTenant;
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    OpenAddDataDialog(){
      let self = this;
      self.AddDataDialog = true;
    },

    CloseAddDataDialog(){
      let self = this;
      self.AddSpendingDetail = "";
      self.AddTenant = null;
      self.AddMinAmount = null;
      self.AddMaxAmount = null;
      self.AddRateCode = null;
      self.AddStatus = null;
      self.AddDataDialog = false;
    },

    CloseEditDataDialog(){
      let self = this;
      self.SpendingID = null;
      self.EditSpendingDetail = "";
      self.EditTenant = null;
      self.EditMinAmount = null;
      self.EditMaxAmount = null;
      self.EditRateCode = null;
      self.EditStatus = null;
      self.EditDataDialog = false;
    },  

    AddDataSpending() {
      let self = this;
      if (
        self.AddSpendingDetail == null ||
        self.AddSpendingDetail == "" || self.AddTenant == null || self.AddMinAmount == null || self.AddMaxAmount == null || self.AddRateCode == null || self.AddStatus == null
      ) {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      } else {
        let temp = {
          SpendingDetail: self.AddSpendingDetail,
          MinAmount: self.AddMinAmount,
          MaxAmount: self.AddMaxAmount,
          Tenant: self.AddTenant.toString(),
          RateCode: self.AddRateCode,
          Status: (self.AddStatus == "1" ? true : self.AddStatus == "2" ? false : null)
        };
        self.LoadingDialog = true;
        // console.log(temp);
        axios
          .post(`${self.url}Spending/AddDataSpending`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.LoadingDialog = false;
            self.AddDataDialog = false;
            self.getDataSpendingList();
          })
          .catch(function(error) {
            self.LoadingDialog = false;
            Swal.fire(error.message);
          });
      }
    },

    UpdateDataSpending() {
      let self = this;
      if (
        self.EditSpendingDetail == null ||
        self.EditSpendingDetail == "" || self.EditTenant == null || self.EditMinAmount == null || self.EditMaxAmount == null || self.EditRateCode == null || self.EditStatus == null
      ) {
        Swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      } else {
        let temp = {
          SpendingID: self.SpendingID,
          SpendingDetail: self.EditSpendingDetail,
          MinAmount: self.EditMinAmount.toString(),
          MaxAmount: self.EditMaxAmount.toString(),
          Tenant: self.EditTenant.toString(),
          RateCode: self.EditRateCode,
          Status: (self.EditStatus == "1" ? true : self.EditStatus == "2" ? false : null)
        };
        self.LoadingDialog = true;
        // console.log(temp);
        axios
          .post(`${self.url}Spending/EditDataSpending`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.LoadingDialog = false;
            self.EditDataDialog = false;
            self.getDataSpendingList();
          })
          .catch(function(error) {
            self.LoadingDialog = false;
            Swal.fire(error.message);
          });
      }
    },
    
    getDataSpendingBySpendingID(id){
      let self = this;
      if(id != 0){
        let temp = {
          spendingID: id
        };
        axios
          .post(`${self.url}Spending/getDataSpendingBySpendingID`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.BindingDataEditSpending(response.data.data);
            }
            self.LoadingDialog = false;
          })
          .catch(function(error) {
            self.LoadingDialog = false;
            Swal.fire(error.message);
          });
      }
    },

    BindingDataEditSpending(data){
      let self = this;
      self.SpendingID = data.spendingId;
      self.EditSpendingDetail = data.spendingDetail;
      self.EditTenant =
        self.TenantList.filter(
          (x) => parseInt(x.tenantID) == parseInt(data.tenantId)
        ).length > 0
          ? self.TenantList.filter(
              (x) => parseInt(x.tenantID) == parseInt(data.tenantId)
            )[0].tenantID
          : null;
      // self.EditTenant = data.tenantId;
      this.GetDataRateCodeFromTenant(self.EditTenant);
      self.EditMinAmount = data.minAmount;
      self.EditMaxAmount = data.maxAmount;
      self.EditRateCode = parseInt(data.rateCode);
      self.EditStatus = data.active == true ? "1" : data.active == false ? "2" : null;

      
      self.EditDataDialog = true;
      // console.log(data);
    },
  },
};
</script>
    <style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  /* cursor: pointer; */
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
    