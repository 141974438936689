<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Rate Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="3" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchRate(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                  style="text-align: end"
                ></v-col>
                <!-- <v-col cols="12" sm="6" md="4" lg="2" style="text-align: end">
                
                </v-col> -->
                <v-col cols="12" sm="6" md="4" lg="3" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getRateList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenRateDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="RateList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.rateCode }}</td>
                    <td class="header-table-css">{{ item.templateName }}</td>

                    <td class="header-table-css">{{ item.tenantName }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>

                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 140px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenRateDialog('edit', item.rateCode)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveRateByRateID(item.rateCode)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="RateDialog" persistent max-width="850px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Rate </span>
          <span v-else class="text-h5">Create Rate </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="DataTenant"
                  item-value="tenantID"
                  item-text="tenantName"
                  label="Tenant"
                  v-model="tenantID"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="MasRateTemplate"
                  item-value="templateID"
                  item-text="templateNameTH"
                  label="Template"
                  v-model="Template"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-checkbox
                  v-model="expiredStatus"
                  :label="`Expired Status `"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-menu
                  :disabled="!expiredStatus"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :disabled="!expiredStatus"
                      v-model="startDate"
                      label="Start Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @change="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-menu
                  :disabled="!expiredStatus"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :disabled="!expiredStatus"
                      v-model="expiredDate"
                      label="End Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expiredDate"
                    @change="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-checkbox
                  v-model="Allday"
                  :label="`All Day`"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-autocomplete
                  :disabled="Allday"
                  :items="['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat ', 'Sun']"
                  label="Day"
                  v-model="Day"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-checkbox
                  v-model="LimitStatus"
                  :label="`Limit Status `"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-autocomplete
                  :disabled="!LimitStatus"
                  :items="MasLimit"
                  item-value="limitUseID"
                  item-text="descriptionTH"
                  label="LimitUseID"
                  v-model="LimitUseID"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
             
                <v-text-field
                  v-model="roundHH"
                  label="Round HH"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="roundMM"
                  label="Round MM"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="fineDamagedCard"
                  label="Damage Card"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="fineLostCard"
                  label="Lost Card"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="MasOvernight"
                  item-value="overNightID"
                  item-text="overNightTypeNameTH"
                  label="Over Night"
                  v-model="overNightID"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="['CAR', 'MOTOR CYCLE']"
                  label="Vehicle Type"
                  v-model="vehicleType"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
             
             <v-text-field
               v-model="startChargeTime"
               label="StartCharge Time"
               clearable
             ></v-text-field>
           </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-switch
                v-model="Status"
                hide-details
                inset
                :label="Status ? 'Active' : 'InActive'"
              ></v-switch>
            </v-col>
            </v-row>
         
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addRate()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addRate()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="RateDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog"  persistent  width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageRate",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      RateList: [],
      search: "",
      activeStatus: null,
      startDate: "",
      expiredDate: "",
      menuStartDate: false,
      menuEndDate: false,
      RateDialog: false,
      EditStatus: false,

      roundHH : "",
      roundMM : "",
      fineLostCard : null,
      fineDamagedCard : null,
      // Add Edit
      startChargeTime: 0,
      rateID: 0,
      rateName: "",
      rateNameTH: "",
      Status: true,
      MasLimit: [],
      MasOvernight: [],
      MasRateTemplate: [],
      DataTenant: [],

      tenantID: null,
      Template: null,
      overNightID: null,
      LimitUseID: null,
      LimitStatus: false,
      Allday: false,
      Day: '',

      vehicleType: "",
      rateCode: 0,
      expiredStatus: false,

      Tempheaders: [
        { text: "No", value: "number" },
        { text: "RateName", value: "rateName" },
        { text: "RateNameTH", value: "rateNameThai" },
        { text: "CreateDate", value: "createTime" },
        { text: "Active", value: "active" },
        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Rate Code",
          align: "left",
          sortable: false,
        },
        {
          text: "Template Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Tenant Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Create Time",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    Allday() {
      let self = this;
      if (self.Allday) {
        self.Day = "";
      }
    },
  },

  async mounted() {
    let self = this;
    
    await self.getDropdownMasLimit();
    await self.getDropdownMasOvernight();
    await self.getDropdownMasRateTemplate();
    await self.getDataTenant();
    await self.getRateList();
  },

  methods: {
    getDataTenant() {
      let self = this;
      // self.LoadingDialog = true;
      axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataTenant = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    getDropdownMasRateTemplate() {
      let self = this;
      // self.LoadingDialog = true;

      axios
        .get(`${self.url}Rate/getDropdownMasRateTemplate`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MasRateTemplate = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          // self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },
    getDropdownMasOvernight() {
      let self = this;
      // self.LoadingDialog = true;

      axios
        .get(`${self.url}Rate/getDropdownMasOvernight`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MasOvernight = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          // self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    getDropdownMasLimit() {
      let self = this;
      // self.LoadingDialog = true;

      axios
        .get(`${self.url}Rate/getDropdownMasLimit`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MasLimit = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    getRateList() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            // console.log(self.RateList);
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    SearchRate(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var month = DateData.getMonth() + 1;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        DateData.getDate() +
        "/" +
        month +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;
      if (that.rateName == "" || that.rateNameTH == "") {
        return true;
      } else {
        return false;
      }
    },

    addRate() {
      let that = this;
      // if (that.checkVakidates()) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;limit
      // }
      that.LoadingDialog = true;

      if (!that.EditStatus) {
        let temp = {
          tenantID: that.tenantID,
          templateID: that.Template,
          startDate: that.startDate,
          expiredDate: that.expiredDate,
          alldayCheck: that.Allday,
          weekDay: that.Day == '' ? null : that.Day,
          limitStatus: that.LimitStatus,
          limitUseID: that.LimitUseID,
          roundHH: that.roundHH,
          roundMM: that.roundMM,
          fineDamagedCard: that.fineDamagedCard,
          fineLostCard: that.fineLostCard,
          overNightID: that.overNightID,
          vehicleType: that.vehicleType,
          expiredStatus: that.expiredStatus,
          startChargeTime: parseInt(that.startChargeTime) ,
          active: that.Status,
        };
        axios
          .post(`${that.url}Rate/addRate`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getRateList();
            that.RateDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            Swal.fire("Error!", error.message);
          });
      } else {
        let temp = {
          rateCode: that.rateCode,
          tenantID: that.tenantID,
          templateID: that.Template,
          startDate: that.startDate,
          expiredDate: that.expiredDate,
          alldayCheck: that.Allday,
          weekDay: that.Day == '' ? null : that.Day,
          limitStatus: that.LimitStatus,
          limitUseID: that.LimitUseID,
          roundHH: that.roundHH,
          roundMM: that.roundMM,
          fineDamagedCard: that.fineDamagedCard,
          fineLostCard: that.fineLostCard,
          overNightID: that.overNightID,
          vehicleType: that.vehicleType,
          expiredStatus: that.expiredStatus,
          startChargeTime: parseInt(that.startChargeTime) ,
          active: that.Status,
        };
        axios
          .post(`${that.url}Rate/editRate`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getRateList();
            that.RateDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            Swal.fire("Error!", error.message);
          });
      }

     
    },

    GetColor(value) {
      if (value == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenRateDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;


        self.rateCode = 0 ;
        self.tenantID = null ;
        self.Template = null ;
        self.startDate = '' ;
        self.expiredDate = '' ;
        self.Allday = false ;
        self.Day = [] ;
        self.LimitStatus = false ;
        self.LimitUseID = null ;
        self.roundHH = '' ;
        self.roundMM = '' ;
        self.fineDamagedCard = null ;
        self.fineLostCard = null ;
        self.overNightID = null ;
        self.vehicleType = '' ;
        self.expiredStatus = false ;
        self.startChargeTime = 0 ;
        self.Status = true ;


        self.RateDialog = true;
      } else {
        // self.BindingEditData(data);
        self.getMasRateByRateCode(data);
      }
    },


    getMasRateByRateCode(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        RateCode : data,
      };

      axios
        .post(`${self.url}Rate/getMasRateByRateCode`,temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.ProfileList = response.data.data;
            console.log(response.data);
            self.BindingEditData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    inActiveRateByRateID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        RateCode: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Role ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Rate/inActiveRateByRateID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getRateList();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
                that.getRateList();
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              Swal.fire("Error!", error.message);
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      
      self.rateCode = data.rateCode ;
      self.tenantID = data.tenantID ;
      self.Template = data.templateID ;
      self.startDate = data.startDate ;
      self.expiredDate = data.expiredDate ;
      self.Allday = data.alldayCheck ;
      self.Day = data.weekDay ;
      self.LimitStatus = data.limitStatus ;
      self.LimitUseID = data.limitUseID ;
      self.roundHH = data.roundHH ;
      self.roundMM = data.roundMM ;
      self.fineDamagedCard = data.fineDamagedCard ;
      self.fineLostCard = data.fineLostCard ;
      self.overNightID = data.overNightID ;
      self.vehicleType = data.vehicleType ;
      self.expiredStatus = data.expiredStatus ;
      self.startChargeTime = data.startChargeTime ;
      self.Status = data.active ;

  


      self.RateDialog = true;
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
