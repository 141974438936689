<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
        ? { display: 'contents',  }
          : { display: 'contents',  },
      ]"
    >
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card variant="outlined">
                <v-card-title style="font-weight: bolder"
                  >Welcome back
                  <span class="ml-5" style="color: #22577a">{{
                    username
                  }}</span>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        
        </v-card-text>
      </div>

      <div>

        <v-row style="padding: 20px">
          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Time Period
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Entry : Past 7 Days
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="padding: 20px">
          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% - 32px)"
                height="50"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Entry VMS
                </v-card-title>
              </v-sheet>
              <!-- <v-card-title> Latest Entry </v-card-title> -->
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Name</th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Floor</th>
                      <th class="text-center">Tenent</th>
                      <th class="text-center">DateTime</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in existTowerEntry" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.existType }}</td>
                      <td class="text-center">{{ 'L' + Math.floor(Math.random() * 10) }}</td>
                      <td class="text-center">{{'KGI' }}</td>
                      <td class="text-center">{{ ChangeFormatDate(item.exitDatetime) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% - 32px)"
                height="50"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Exit VMS
                </v-card-title>
              </v-sheet>
              <!-- <v-card-title> Latest Exit </v-card-title> -->
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Name</th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Floor</th>
                      <th class="text-center">Tenent</th>
                      <th class="text-center">DateTime</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in existTowerExit" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.existType }}</td>
                      <td class="text-center">{{ 'L' + Math.floor(Math.random() * 10) }}</td>
                      <td class="text-center">{{ 'KGI' }}</td>
                      <td class="text-center">{{ ChangeFormatDate(item.exitDatetime) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>


    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "DashboardVMS",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      show: false,
      items: [10, 15, 20],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      TotalNumberDataDashboard: [],
      responseMemberExit: [],
      responsespInviteVisitorExistSummary: [],

      existTower: [],
      existTowerEntry: [],
      existTowerExit: [],
      username: localStorage.getItem("Username"),
      TotalTenant: 70,
      TotalMember: 80,
      Member: 90,
      Visitor: 95,
      dateNow: "",
      L1: 225,
      B1M: 353,
      B1: 352,
      B2: 100,
      B3: 85,
      B4: 67,
      TUNNEL: 44,

      seriesL1: [75],
      seriesB1M: [65],
      seriesB1: [40],
      seriesB2: [69],
      seriesB3: [87],
      seriesB4: [53],
      seriesTUNNEL: [12],
      chartOptionsL1: {
        chart: {
          height: 200,
          width: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsB1M: {
        chart: {
          height: 200,
          width: 200,
          type: "radialBar",
        
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsB1: {
        chart: {
          height: 350,
          type: "radialBar",
      
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsB2: {
        chart: {
          height: 350,
          type: "radialBar",
          
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsB3: {
        chart: {
          height: 350,
          type: "radialBar",
         
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsB4: {
        chart: {
          height: 350,
          type: "radialBar",
         
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      chartOptionsTUNNEL: {
        chart: {
          height: 350,
          type: "radialBar",
         
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Percent"],
      },
      series: [
        {
          name: "count",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //14
        },
      ],
      series2: [
        {
          name: "Member",
          data: [29, 40, 35, 30, 44,30, 20],//7
        },
        {
          name: "Visitor",
          data: [80, 60, 70, 89, 75, 93, 40],//7
        },
      ],

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Time Period",
          },
          categories: [
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val + " People";
            },
          },
        },
      },
      chartOptions2: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Datetime",
          },
          categories: [
            "2024-02-15",
            "2024-02-16",
            "2024-02-17",
            "2024-02-18",
            "2024-02-19",
            "2024-02-20",
            "2024-02-21",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " People";
            },
          },
        },
      },
    };
  },

  computed: {},

  watch: {},
  mounted() {
    let that = this;
    that.dateNow = new Date();
    that.getTotalNumberDataDashboard();
  },
  methods: {
    getTotalNumberDataDashboard() {
      let that = this;
      axios
        .get(`${that.url}Dashboard/getTotalNumberDataDashboard`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TotalNumberDataDashboard =
              response.data.data.responseMemberAndTenant;
            that.responseMemberExit = response.data.data.responseMemberExit;
            that.responsespInviteVisitorExistSummary =
              response.data.data.responsespInviteVisitorExistSummary;
            that.existTower = response.data.data.existTower;
            that.existTowerEntry = that.existTower.filter(
              (a) => a.isExist == false
            );
            that.existTowerExit = that.existTower.filter(
              (a) => a.isExist == true
            );

            that.getPeriod();


            that.show = true;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          Swal.fire("Error!", error.message);
          // eventBus.$emit('logout');
        });
    },

    logout() {
      eventBus.$emit("logout");
    },

    getPeriod() {
      let that = this;

      console.log(that.series);
      console.log(that.series);
      that.series[0].data[0] = 2;
      that.series[0].data[1] = 4;
      that.series[0].data[2] = 10;
      that.series[0].data[3] = 15;
      that.series[0].data[4] = 18;
      that.series[0].data[5] = 20;
      that.series[0].data[6] = 40;
      that.series[0].data[7] = 30;
      that.series[0].data[8] = 50;
      that.series[0].data[9] = 70;
      that.series[0].data[10] = 50;
      that.series[0].data[11] = 30;
      that.series[0].data[12] = 20;
      that.series[0].data[13] = 15;
      that.series[0].data[14] = 10;
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      // var ampm = hours >= 12 ? "PM" : "AM";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getMonth() +
        1 +
        "/" +
        DateData.getDate() +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #6ACFC7;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
