<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { display: 'contents' }
          : { display: 'contents' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <v-card-text>
          <div>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-card class="mx-auto" height="150" elevation="7">
                      <v-row
                        style="
                          display: flex;
                          justify-content: space-around;
                          margin-top: 10px;
                        "
                      >
                        <v-col cols="12" sm="5" md="5">
                          <label>บริษัท</label>
                          <v-autocomplete
                            class="mt-4"
                            ref="inputTenant"
                            :items="tenantArray"
                            item-value="tenantID"
                            item-text="tenantName"
                            label="Tenant"
                            v-model="tenant"
                            clearable
                            outlined
                            @change="getLocationId()"
                            @click:clear="
                              locationID = '';
                              tenant = '';
                            "
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="8" sm="5" md="5">
                          <label>Location</label>
                          <v-autocomplete
                            class="mt-4"
                            append-icon=""
                            :items="locationList"
                            item-value="locationID"
                            :item-text="
                              (item) =>
                                'Floor : ' +
                                item.floorName +
                                ' - Zone : ' +
                                item.zoneName
                            "
                            label="Location Name"
                            v-model="locationID"
                            clearable
                            outlined
                          ></v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="
                            display: flex;
                            justify-content: space-around;
                            margin-bottom: 5%;
                          "
                        >
                        </v-col>
                      </v-row>

                      <v-card-actions>
                        <v-btn outlined rounded text> Button </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-card class="mx-auto" height="480" elevation="7">
                      <v-card-title
                        style="display: flex; justify-content: center"
                      >
                        <v-radio-group v-model="radios" row>
                          <v-radio label="ID Card" value="1"></v-radio>
                          <v-radio label="Passport" value="2"></v-radio>
                        </v-radio-group>
                        <v-btn color="primary" @click="Sync()"> Sync </v-btn>
                        <v-btn style="margin-left: 10px" @click="ReSet()">
                          Clear
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row style="padding: 20px">
                          <v-col cols="12" md="12" lg="12" sm="12">
                            <v-row>
                              <v-col cols="3" md="3" lg="3" sm="3">
                                <!-- <v-text-field
                                  label="Prefix"
                                  :rules="rules"
                                  hide-details="auto"
                                  clearable
                                  outlined
                                  v-model="prefix"
                                ></v-text-field> -->
                                <v-select
                                  :items="['Mr.', 'Ms.']"
                                  label="Prefix"
                                  :rules="rules"
                                  v-model="prefix"
                                  required
                                  clearable
                                  outlined
                                ></v-select>
                              </v-col>
                              <v-col cols="4" md="4" lg="4" sm="4">
                                <v-text-field
                                  label="First Name"
                                  :rules="rules"
                                  hide-details="auto"
                                  clearable
                                  outlined
                                  v-model="firstName"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" md="4" lg="4" sm="4">
                                <v-text-field
                                  label="Last Name"
                                  :rules="rules"
                                  hide-details="auto"
                                  clearable
                                  outlined
                                  v-model="lastName"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="3" md="3" lg="3" sm="3">
                                <v-select
                                  :items="['Male', 'Female']"
                                  label="Sex"
                                  :rules="rules"
                                  v-model="sex"
                                  required
                                  clearable
                                  outlined
                                ></v-select>
                              </v-col>
                              <v-col cols="3" md="3" lg="3" sm="3">
                                <v-select
                                  :items="['IDCard', 'Passport', 'Other']"
                                  label="Type ID"
                                  :rules="rules"
                                  v-model="typeid"
                                  required
                                  outlined
                                ></v-select>
                              </v-col>
                              <v-col cols="5" md="5" lg="5" sm="5">
                                <v-text-field
                                  :label="
                                    typeid == 'IDCard'
                                      ? 'ID Card should be 13 characters'
                                      : 'should be 9 to 16 characters'
                                  "
                                  :rules="rules"
                                  hide-details="auto"
                                  outlined
                                  v-model="idCard"
                                ></v-text-field>
                              </v-col>
                              <!-- <v-col cols="4" md="4" lg="4" sm="4">
                                <v-text-field
                                  label="Date Of Birth"
                                  :rules="rules"
                                  hide-details="auto"
                                  clearable
                                  outlined
                                  v-model="birth"
                                ></v-text-field>
                              </v-col>  -->
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions
                        style="display: flex; justify-content: center"
                      >
                        <!-- <v-btn color="primary"> Sync </v-btn>
                        <v-btn> Clear </v-btn> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-card class="mx-auto" height="665" elevation="7">
                  <v-card-text v-if="!printData">
                    <!-- <label for="camera">Camera</label>
                     -->
                    <v-sheet>
                      <v-toolbar flat color="primary" dark>
                        <v-toolbar-title>Camera</v-toolbar-title>
                      </v-toolbar>
                      <v-row
                        style="
                          margin-top: 10px;
                          display: flex;
                          justify-content: space-evenly;
                        "
                      >
                      </v-row>
                      <v-row
                        style="
                          margin-top: 10px;
                          display: flex;
                          justify-content: space-evenly;
                        "
                      >
                        <v-col
                          cols="12"
                          md="5"
                          style="
                            margin-top: 10px;
                            display: flex;
                            justify-content: space-around;
                          "
                        >
                          <v-card
                            v-if="!isPhotoTaken"
                            width="260"
                            height="200"
                            elevation="0"
                            style="display: flex; justify-content: space-around"
                          >
                            <video
                              ref="camera"
                              width="250"
                              height="200"
                              autoplay
                            ></video>
                          </v-card>

                          <canvas
                            v-show="isPhotoTaken"
                            id="photoTaken"
                            ref="canvas"
                            width="250"
                            height="200"
                          ></canvas>
                        </v-col>

                        <v-col
                          cols="12"
                          md="5"
                          style="
                            margin-top: 10px;
                            display: flex;
                            justify-content: space-around;
                          "
                        >
                          <v-card
                            v-if="!isPhotoTaken"
                            width="250"
                            height="200"
                            elevation="0"
                            style="display: flex; justify-content: space-around"
                          >
                            <video
                              ref="camera2"
                              width="250"
                              height="200"
                              autoplay
                            ></video>
                          </v-card>
                          <canvas
                            v-show="isPhotoTaken"
                            id="photoTaken2"
                            ref="canvas2"
                            width="250"
                            height="200"
                          ></canvas>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-row style="display: flex; justify-content: center">
                          <v-btn
                            tile
                            color="success"
                            class="mt-5"
                            style="margin: 10px"
                            @click="takePhoto()"
                          >
                            <v-icon left> mdi-camera </v-icon>
                            Take Photo
                          </v-btn>
                          <v-btn
                            tile
                            color="secondary"
                            class="mt-5"
                            style="margin: 10px"
                            @click="getidcamera()"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </v-row>
                      </v-row>

                      <v-row
                        v-if="!printData && logCarparkID != ''"
                        style="
                          margin-top: 40px;
                          display: flex;
                          justify-content: center;
                        "
                      >
                        <v-col
                          cols="12"
                          sm="5"
                          md="5"
                          style="display: flex; justify-content: center"
                        >
                          <v-text-field
                            hide-details="auto"
                            clearable
                            outlined
                            disabled
                            v-model="logCarparkID"
                            style="width: 100px; color: black"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row v-if="!printData" style="margin-top: 20px">
                        <v-row style="display: flex; justify-content: center">
                          <v-btn
                            tile
                            color="success"
                            class="mt-5"
                            style="margin: 10px"
                            @click="scanQrcodeCarpark()"
                          >
                            Register By QrCode
                          </v-btn>
                        </v-row>
                      </v-row> -->
                      <v-row v-if="!printData" style="margin-top: 40px">
                        <v-row style="display: flex; justify-content: center">
                          <v-btn
                            tile
                            color="success"
                            class="mt-5"
                            style="margin: 10px"
                            @click="scanQrcodeCarpark()"
                          >
                            Register By QrCode
                          </v-btn>
                          <v-btn
                            tile
                            color="success"
                            class="mt-5"
                            style="margin: 10px"
                            @click="Submit()"
                          >
                            Submit
                          </v-btn>
                        </v-row>
                      </v-row>
                    </v-sheet>
                  </v-card-text>
                  <v-card-text
                    v-if="printData"
                    style="display: flex; justify-content: center"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="display: flex; justify-content: center"
                      >
                        <v-card
                          id="capture"
                          elevation="7"
                          height="665"
                          style="
                            background-color: rgb(255, 255, 255) !important;
                            height: 470px;
                            page-break-after: always;
                            width: 350px;
                            padding: 0;
                          "
                        >
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="background-color: #fff"
                          >
                            <v-img
                              height="38"
                              width="400"
                              src="@/assets/logo-01.png"
                            ></v-img>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              padding: 0;
                              background-color: #fff;
                            "
                          >
                            <label style="font-size: 25px">{{
                              tenantName
                            }}</label>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              padding: 0;
                              background-color: #fff;
                            "
                          >
                            <label
                              style="background-color: #fff; font-size: 20px"
                              >Floor : {{ floorName }} / Zone :
                              {{ zoneName }}</label
                            >
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              padding: 0;
                              display: grid;
                              justify-content: space-evenly;
                              background-color: #fff;
                            "
                          >
                            <qr-code
                              style="margin: 30px"
                              :size="200"
                              :text="Qrcode"
                              error-level="L"
                            ></qr-code>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              padding: 0;
                              background-color: #fff;
                            "
                          >
                            <label style="font-size: 20px"
                              >ใช้ได้ตั้งแต่ :
                              {{ ChangeFormatDate(startDate) }}</label
                            >
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              padding: 0;
                              background-color: #fff;
                            "
                          >
                            <label style="font-size: 20px"
                              >ใช้ได้จนถึง :
                              {{ ChangeFormatDate(endDate) }}</label
                            >
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            lg="12"
                            style="
                              text-align: center;
                              background-color: #fff;
                              font-size: 18px;
                            "
                          >
                            <label>** สำหรับเข้าและออกอาคารครั้งเดียว **</label>
                          </v-col>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="display: flex; justify-content: center"
                      >
                        <v-btn
                          tile
                          color="success"
                          class="mt-5"
                          style="margin: 10px"
                          @click="print()"
                        >
                          Print
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- <v-card-actions>
                    <v-btn outlined rounded text> Button </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="scanQrcode" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Scan Qr Code</span>
          <v-spacer></v-spacer>
          <br />
          <v-progress-circular indeterminate size="50 ">
            <span style="font-size: 20px">{{ timerCount }}</span>
          </v-progress-circular>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <img
                  height="auto"
                  width="300px"
                  style="margin-top: 15%; margin-left: auto; margin-right: auto"
                  src="@/assets/terminal.png"
                />
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="searchCarpark"
                ref="inputCarpark"
                style="width: 0px; color: black"
                placeholder-color="#ACACAC"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="gray" @click="scanQrcode = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </v-dialog>

    <v-dialog v-model="LoadingDialog2" persistent width="auto">
      <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div style="color: #fff">Waiting PDPA</div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import html2canvas from "html2canvas";
import { eventBus } from "../main";
// import PSPDFKitContainer from "@/components/PSPDFKitContainer";

export default {
  name: "Reception",
  components: {
    // PSPDFKitContainer,
  },

  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data() {
    return {
      radios: "1",
      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      urlReader: enurl.apiUrlReader,
      urlCarpark: enurl.apiUrlCarpark,
      LoadingDialog: false,
      LoadingDialog2: false,
      items: [10, 15, 20],
      itemsHandheld: [10, 15, 20],
      itemsOpenBar: [10, 15, 20],
      no_run: null,
      no_runHandheld: null,
      no_runOpenBar: null,
      pageCount: 0,
      pageCountHandheld: 0,
      pageCountOpenBar: 0,
      page: 1,
      pageHandheld: 1,
      pageOpenBar: 1,
      itemsPerPage: 10,
      itemsPerPageHandheld: 10,
      itemsPerPageOpenBar: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      typeid: "IDCard",

      HandheldList: [],
      OpenBarList: [],
      RateList: [],
      ProfileList: [],
      MasterOpenBar: [],
      TerminalHandheld: [],
      TransactionOpenBar: [],
      terminalList: [],
      terminal: "",
      openBarIssueList: [],
      openBarIssue: "",
      search: "",
      searchHandheld: "",
      searchOpenBar: "",
      LicensePlate: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      RateDialog: false,
      EditStatus: false,

      // Add Edit
      rateID: 0,
      templateName: "",
      templateNameTH: "",
      templateNameEng: "",

      rateNameTH: "",
      Note: "",
      Status: true,

      invoiceStatus: false,
      lumpSumStatus: false,
      freeStatus: false,
      periodStatus: false,
      amount: 0,
      templateTime: 0,

      templateID: 0,
      startPeriod: null,
      endPeriod: null,
      menu2: false,
      modal_startPeriod: false,
      modal_endPeriod: false,

      imageCar: require("@/assets/barrier-and-car.svg"),
      imageCamera: require("@/assets/photo-camera.svg"),
      nameTemplateTH: "",
      nameTemplateEng: "",
      pdpa: "",
      profileIDFirst: 0,
      profileIDSecond: 0,
      conjunctionID: 0,
      tenantArray: [],
      locationList: [],
      ResponsePassport: [],
      ResponseSmartCard: [],
      thaiPersonalInfo: [],
      englishPersonalInfo: [],
      addressInfo: [],
      tenant: "",
      tenantName: "",
      zone: "",
      floor: "",
      zoneName: "",
      floorName: "",
      locationID: "",

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      idCamera: [],
      cameraBase64: "",
      cameraBase64_2: "",
      stepper: 1,
      base64: "",
      Qrcode: "",

      reference_documents: "",

      documentNumber: "",
      prefixEN: "",
      firstnameEN: "",
      middlenameEN: "",
      lastnameEN: "",
      sexEN: "",
      prefixTH: "",
      firstnameTH: "",
      middlenameTH: "",
      lastnameTH: "",
      sexTH: "",

      prefix: "",
      firstName: "",
      lastName: "",
      sex: "",
      idCard: "",
      birth: "",
      searchCarpark: "",

      SyncData: false,
      type: "1",
      cardData: [],
      printData: false,
      scanQrcode: false,
      messageQr: "",
      timerCount: 10,
      logCarparkID: "",
      disable: false,
      errormessage: "",

      rules: [
        (value) => !!value || "",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Template Name TH",
          align: "left",
          sortable: false,
        },
        {
          text: "Template Name Eng",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersHandheld() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "ID",
          align: "left",
          sortable: false,
        },
        {
          text: "Terminal Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Open Bar",
          align: "left",
          sortable: false,
        },
      ];
    },
    headersOpenBar() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Trn LogID",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Terminal Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn User ID",
          align: "left",
          sortable: false,
        },
        {
          text: "Trn Car ID",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Reason ID",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Reason",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Direction",
        //   align: "center",
        //   sortable: false,
        // },
      ];
    },
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // setTimeout(() => (this[l] = false), 3000);
      if (this.LoadingDialog == false) {
        this.loader = null;
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
            if (this.timerCount % 2) {
              this.$refs.inputCarpark.focus();
            }
            if (this.timerCount == 0) {
              this.scanQrcode = false;
            }
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  // created() {
  //   eventBus.$on("closeloading", () => {
  //     this.closeloading();
  //   });
  // },

  mounted() {
    let self = this;

    self.getDataTenantListVMS();
    self.getidcamera();
    localStorage.setItem("PDPA", "N");
    localStorage.setItem("LogId", "");
    self.GetIp();
    // self.getDataMasterOpenBar();
    // self.getTerminalHandheldOpenBar();
    // self.getTransacOpenBar();
    document.addEventListener("keyup", this.onKeyup);
  },

  methods: {
    GetIp() {
      let self = this;

      axios
        .get(`${self.urlReader}General/GetIp`)
        .then(function (response) {
          //  console.log(response.data);
          localStorage.setItem("IP", response.data);
        })
        .catch(function (error) {
          self.MessageAlert = error;
        });
    },

    scanQrcodeCarpark() {
      let self = this;

      self.searchCarpark = "";
      self.timerCount = 10;
      self.scanQrcode = true;

      // setTimeout(() => {
      //   self.$refs.inputCarpark.focus();
      //         }, 2000);
    },
    Sync() {
      if (this.radios == "1") {
        this.SmartCardReader();
      } else {
        this.PassportReader();
      }
    },
    onKeyup(event) {
      if (event.key === "Enter") {
        if (this.scanQrcode == true) {
          if (this.searchCarpark != "") {
            this.SearchQrcode(this.searchCarpark);
          }
        } else if (localStorage.getItem("PDPA") == "N") {
          this.PDPA();
        } else if (this.tenant == "") {
          Swal.fire({
            icon: "warning",
            title: "Please Select a Tenant",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (
          this.prefix == "" &&
          this.firstName == "" &&
          this.lastName == "" &&
          this.sex == "" &&
          this.idCard == ""
        ) {
          if (this.radios == "1") {
            this.SmartCardReader();
          } else {
            this.PassportReader();
          }
        } else if (this.checkData() == false) {
          let error = this.errormessage;
          Swal.fire({
            icon: "warning",
            title: error,
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (this.cameraBase64 == "" && this.cameraBase64_2 == "") {
          this.takePhoto();
        } else if (!this.printData) {
          this.addDataVisitor();
        } else if (this.printData) {
          this.print();
        }
      }
    },

    checkData() {
      console.log("Checking");
      if (
        this.prefix == "" ||
        this.firstName == "" ||
        this.lastName == "" ||
        this.sex == "" ||
        this.idCard == ""
      ) {
        this.errormessage = "Please fill in information";
        return false;
      } else if (this.typeid == "IDCard") {
        if (this.validNationalID(this.idCard)) {
          return true;
        } else {
          this.errormessage = "Incorrect ID card Format";
          return false;
        }
      } else if (this.idCard.length < 9 && this.typeid != "IDCard") {
        this.errormessage = "Length Data must be 9 to 16 characters";
        return false;
      } else if (this.idCard.length >= 9 && this.typeid != "IDCard") {
        return true;
      }
      return false;
    },

    validNationalID(id) {
      if (id.length != 13) return false;
      // STEP 1 - get only first 12 digits
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        // STEP 2 - multiply each digit with each index (reverse)
        // STEP 3 - sum multiply value together
        sum += parseInt(id.charAt(i)) * (13 - i);
      }
      // STEP 4 - mod sum with 11
      let mod = sum % 11;
      // STEP 5 - subtract 11 with mod, then mod 10 to get unit
      let check = (11 - mod) % 10;
      // STEP 6 - if check is match the digit 13th is correct
      if (check == parseInt(id.charAt(12))) {
        return true;
      }
      return false;
    },

    SearchQrcode(data) {
      let self = this;
      localStorage.setItem("LogId", data);
      let temp = {
        logCarparkID: data,
      };
      axios
        .post(`${self.url}VMS/checkIsExitCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // console.log(response.data.data);
            // self.cardData.referenceQr = localStorage.getItem("LogId");

            if (response.data.data.trn_Exit_Status == 0) {
              self.logCarparkID = response.data.data.trn_Log_ID;
              Swal.fire({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000,
              });
              self.scanQrcode = false;
            } else if (response.data.data.trn_Exit_Status == 1) {
              self.logCarparkID = "";
              Swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000,
              });
              self.scanQrcode = false;
            }
          }
          if (response.data.status == 1) {
            // console.log(response.data.data);
            self.logCarparkID = "";
            Swal.fire({
              icon: "warning",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch(function () {
          self.logCarparkID = "";
          Swal.fire({
            icon: "warning",
            title: "Error",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },

    Submit() {
      if (localStorage.getItem("PDPA") == "N") {
        this.PDPA();
      } else if (this.tenant == "") {
        Swal.fire({
          icon: "warning",
          title: "Please Select a Tenant",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (
        this.prefix == "" &&
        this.firstName == "" &&
        this.lastName == "" &&
        this.sex == "" &&
        this.idCard == ""
      ) {
        if (this.radios == "1") {
          this.SmartCardReader();
        } else {
          this.PassportReader();
        }
      } else if (this.cameraBase64 == "" && this.cameraBase64_2 == "") {
        this.takePhoto();
      } else if (!this.printData) {
        this.addDataVisitor();
      } else if (this.printData) {
        this.print();
      }
    },

    PDPA() {
      let self = this;
      localStorage.setItem("PDPA", "N");
      // self.LoadingDialog2 = true;

      //   let check = window.open(
      //   "https://localhost:444/PDPAPage",
      //   "PDPAPage",
      //   "popup"
      // );

      // let check = window.open(
      //   "http://localhost:8080/PDPAPage",
      //   "PDPAPage",
      //   "popup"
      // );

      let check = window.open(
        "https://localhost:444/PDPAPage2",
        "PDPAPage",
        "popup"
      );

      // let check = window.open(
      //   "http://localhost:8080/PDPAPage2",
      //   "PDPAPage",
      //   "popup"
      // );
      check.addEventListener("unload", () => console.log("closed"));

      Swal.fire({
        html: "Waiting for PDPA",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      // self.stepper = 1;
      let refreshIntervalId = setInterval(() => {
        if (localStorage.getItem("PDPA") == "Y") {
          self.LoadingDialog2 = false;
          Swal.close();
          // self.stepper = 2;
          clearInterval(refreshIntervalId);
        } else if (localStorage.getItem("PDPA") == "R") {
          self.locationID = "";
          self.tenant = "";
          self.LoadingDialog2 = false;
          Swal.close();
          // self.stepper = 1;
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    },

    updateValue() {
      if (localStorage.getItem("PDPA") == "Y") {
        // alert(localStorage.getItem("PDPA"));
        return true;
      } else {
        return false;
      }

      // document.getElementById("name").value = param;
    },

    closeloading() {
      let self = this;
      self.LoadingDialog = false;
    },
    print() {
      let self = this;
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        // document.body.appendChild(canvas)
        // console.log(document.body.appendChild(canvas));

        const base64 = canvas.toDataURL("image/png");

        // var myImage = canvas.toDataURL("image/png");
        var tWindow = window.open("");

        tWindow.document.body.appendChild(canvas);

        // focus on the window
        tWindow.focus();

        // print the window
        tWindow.print();

        // reload the page
        tWindow.close();

        // console.log(base64);
        self.base64 = base64;

        self.ReSet();

        // window.print(self.base64);
        // self.printQr(self.base64);
        // var anchor = document.createElement("a");
        // anchor.setAttribute("href", base64);
        // anchor.setAttribute("download", "receipt.png");
        // anchor.click();
        // anchor.focus();
        // anchor.print();
        // anchor.remove();
      });
    },
    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    async getidcamera() {
      // this.LoadingDialog = true;
      this.idCamera = [];

      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
      } else {
        // List cameras and microphones.
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind == "videoinput") {
                this.idCamera.push(device.deviceId);
              }

              // console.log(
              //   `${device.kind}: ${device.label} id = ${device.deviceId}`
              // );
            });
            if (this.idCamera.length > 0) {
              // this.stepper = 3;
              // Swal.fire(
              //   "มีกล้องที่สามารถเชื่อมต่อได้ " + this.idCamera.length + " ตัว"
              // );
              this.toggleCamera();
            }
            // console.log(this.idCamera);
            this.LoadingDialog = false;
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
            this.LoadingDialog = false;
          });
      }
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = true;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 250, 190);

      const context2 = this.$refs.canvas2.getContext("2d");
      context2.drawImage(this.$refs.camera2, 0, 0, 250, 190);

      this.cameraBase64 = this.$refs.canvas.toDataURL();
      this.cameraBase64_2 = this.$refs.canvas2.toDataURL();

      this.cardData.image1 = this.cameraBase64.split(",")[1];
      this.cardData.image2 = this.cameraBase64_2.split(",")[1];

      // context2.drawImage(this.$refs.camera2, 0, 0, 300, 250);
    },
    stopCameraStream() {
      let tracks =
        this.$refs.camera == undefined
          ? null
          : this.$refs.camera.srcObject.getTracks();
      let tracks2 =
        this.$refs.camera2 == undefined
          ? null
          : this.$refs.camera2.srcObject.getTracks();

      if (tracks != null) {
        tracks.forEach((track) => {
          track.stop();
          // track.clear();
        });
      }
      if (tracks2 != null) {
        tracks2.forEach((tracks2) => {
          tracks2.stop();
        });
      }
    },
    createCameraElement() {
      this.isLoading = true;

      if (this.idCamera.length > 0) {
        this.idCamera.forEach((id, index) => {
          const constraints = (window.constraints = {
            audio: false,
            video: {
              deviceId: { exact: id },
            },
          });
          if (index == 0) {
            navigator.mediaDevices
              .getUserMedia(constraints)
              .then((stream) => {
                this.isLoading = false;
                this.$refs.camera.srcObject = stream;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
          if (index == 1) {
            navigator.mediaDevices
              .getUserMedia(constraints)
              .then((stream) => {
                this.isLoading = false;
                this.$refs.camera2.srcObject = stream;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        });
      }
    },

    toggleCamera() {
      // await this.getidcamera();
      // this.isCameraOpen = false;
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();

        setTimeout(() => {
          this.createCameraElement();
        }, 1000);
      } else {
        if (this.idCamera.length == 0) {
          Swal.fire("Check Connect Camera");
        } else {
          this.isCameraOpen = true;
          this.createCameraElement();
        }
      }
    },

    SmartCardReader() {
      let self = this;
      self.LoadingDialog = true;
      self.cardData = [];
      axios
        .get(`${self.urlReader}SmartCardReader/ThaiSmartCardReader`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ResponseSmartCard = response.data.data;
            self.englishPersonalInfo = response.data.data.englishPersonalInfo;
            self.thaiPersonalInfo = response.data.data.thaiPersonalInfo;
            self.addressInfo = response.data.data.addressInfo;
            console.log(response);

            let temp = {
              documentNumber: self.ResponseSmartCard.citizenID,
              prefixEN: self.englishPersonalInfo.prefix,
              firstnameEN: self.englishPersonalInfo.firstName,
              middlenameEN: self.englishPersonalInfo.middleName,
              lastnameEN: self.englishPersonalInfo.lastName,
              sexEN: self.ResponseSmartCard.sex,
              prefixTH: self.thaiPersonalInfo.prefix,
              firstnameTH: self.thaiPersonalInfo.firstName,
              middlenameTH: self.thaiPersonalInfo.middleName,
              lastnameTH: self.thaiPersonalInfo.lastName,
              sexTH: self.ResponseSmartCard.sex,
              image1: "",
              image2: "",
              tenantID: self.tenant,
              towerID: 1,
              zoneID: self.zone,
              floorID: self.floor,
              locationID: self.locationID,
            };

            self.cardData = temp;

            self.prefix = self.englishPersonalInfo.prefix;
            self.firstName = self.englishPersonalInfo.firstName;
            self.lastName = self.englishPersonalInfo.lastName;
            self.sex = self.ResponseSmartCard.sex == "1" ? "Male" : "Female";
            self.idCard = self.ResponseSmartCard.citizenID;

            self.SyncData = true;
            self.type = "1";
            self.LoadingDialog = false;
          } else {
            self.SyncData = false;
            self.LoadingDialog = false;
            Swal.fire("ตรวจสอบอุปกรณ์");
          }
        })
        .catch(function () {
          self.LoadingDialog = false;
          Swal.fire("ตรวจสอบอุปกรณ์");
        });
    },

    PassportReader() {
      let self = this;
      self.LoadingDialog = true;
      axios
        .get(`${self.urlReader}PassportReader/PassportReader`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ResponsePassport = response.data.data;
            console.log(self.ResponsePassport);

            let temp = {
              documentNumber: self.ResponsePassport.idCard,
              prefixEN: "",
              firstnameEN: self.ResponsePassport.firstNameEng,
              middlenameEN: "",
              lastnameEN: self.ResponsePassport.lastNameEng,
              sexEN: self.ResponsePassport.sex == "M" ? "1" : "2",
              prefixTH: "",
              firstnameTH: self.ResponsePassport.firstNameEng,
              middlenameTH: "",
              lastnameTH: self.ResponsePassport.lastNameEng,
              sexTH: self.ResponsePassport.sex == "M" ? "1" : "2",
              image1: "",
              image2: "",
              tenantID: self.tenant,
              towerID: 1,
              zoneID: self.zone,
              floorID: self.floor,
              locationID: self.locationID,
            };

            self.cardData = temp;

            self.prefix = self.ResponsePassport.sex == "M" ? "Mr." : "Ms.";
            self.firstName = self.ResponsePassport.firstNameEng;
            self.lastName = self.ResponsePassport.lastNameEng;
            self.sex = self.ResponsePassport.sex == "M" ? "Male" : "Female";
            self.idCard = self.ResponsePassport.idCard;

            self.SyncData = true;
            self.type = "2";
            self.LoadingDialog = false;
          } else {
            self.SyncData = false;
            self.LoadingDialog = false;
            Swal.fire("ตรวจสอบอุปกรณ์");
          }
        })
        .catch(function () {
          self.LoadingDialog = false;
          Swal.fire("ตรวจสอบอุปกรณ์");
        });
    },
    getTennant() {
      let self = this;
      axios
        .get(`${self.url}Tenant/getDataTenantvms`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    getDataTenantListVMS() {
      let self = this;
      axios
        .get(`${self.url}VMS/getDataTenantListVMS`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;

            console.log("fs");
            // self.locationList = self.tenantArray.locationList;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },
    getLocationId() {
      let self = this;
      if (self.tenant != "") {
        self.locationList = self.tenantArray.find(
          (x) => x.tenantID == self.tenant
        ).locationList;
        // console.log(self.locationList);
        self.locationID = self.locationList[0].locationID;
        self.tenantName = self.tenantArray.find(
          (x) => x.tenantID == self.tenant
        ).tenantName;
        this.$refs.inputTenant.blur();

        self.getZoneandFloor();
      }
    },
    getZoneandFloor() {
      let self = this;

      self.zone = self.locationList.find(
        (x) => x.locationID == self.locationID
      ).zoneID;
      self.floor = self.locationList.find(
        (x) => x.locationID == self.locationID
      ).floorID;
      self.zoneName = self.locationList.find(
        (x) => x.locationID == self.locationID
      ).zoneName;
      self.floorName = self.locationList.find(
        (x) => x.locationID == self.locationID
      ).floorName;
    },

    getTowerByPersonId(data) {
      let self = this;

      if (data.length == 0) {
        self.defaultFloorList = [];
        return;
      }
      let temp = {
        personId: self.personID == "" ? null : self.personID,
        tenantArray: data,
      };
      axios
        .post(`${self.url}Member/getTowerByPersonId`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.defaultFloorList = response.data.data;
            if (self.defaultFloorList.length > 0) {
              // console.log(response.data.data);
              self.towerNameList = self.defaultFloorList[0].towerID;
            }
            // console.log(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    printQr(base64) {
      let self = this;
      base64 = base64.split(",")[1];
      self.LoadingDialog = true;
      let temp = {
        base64: base64,
      };

      axios
        .post(`${self.urlReader}Main/PrintQRCodeVMS`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.ProfileList = response.data.data;
            // console.log(response.data.data);
            // self.stepper = 1;
            self.ReSet();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          // self.stepper = 1;
          self.ReSet();
          Swal.fire(error.message);
        });
    },

    addDataVisitor() {
      let self = this;
      if (self.disable == true) {
        return;
      }
      self.disable = true;
      // self.LoadingDialog = true;
      let temp = {};
      if (!self.SyncData) {
        temp = {
          documentNumber: this.idCard,
          prefixEN: this.prefix,
          firstnameEN: this.firstName,
          middlenameEN: "",
          lastnameEN: this.lastName,
          sexEN: this.sex,
          prefixTH: this.prefix,
          firstnameTH: this.firstName,
          middlenameTH: "",
          lastnameTH: this.lastName,
          sexTH: this.sex,
          image1:
            self.cameraBase64 != "" ? self.cameraBase64.split(",")[1] : "",
          image2:
            self.cameraBase64_2 != "" ? self.cameraBase64_2.split(",")[1] : "",
          tenantID: self.tenant,
          towerID: 1,
          zoneID: self.zone,
          floorID: self.floor,
          locationID: self.locationID,
          referenceQr: localStorage.getItem("LogId"),
          ip: localStorage.getItem("IP"),
          type: "Manually",
        };
      } else {
        self.cardData.referenceQr = localStorage.getItem("LogId");
        self.cardData.ip = localStorage.getItem("IP");
        self.cardData.type = self.type == "1" ? "ID Card" : "Passport";
        temp = self.cardData;
      }

      axios
        .post(`${self.url}VMS/addDataVisitor`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.ProfileList = response.data.data;
            // console.log(response.data.data);
            if (self.logCarparkID != "") {
              self.printData = false;
              Swal.fire({
                icon: "success",
                title: "Register Success",
                showConfirmButton: false,
                timer: 2000,
              });
              setTimeout(() => {
                self.ReSet();
              }, 2000);
            } else {
              var s = new Date();
              self.startDate = s;
              let dateOut = response.data.data.split('"')[7].replace("Z", "");

              self.endDate = dateOut;
              self.Qrcode = response.data.data;
              self.printData = true;
            }

            // self.stepper = 4;
          }
          if (response.data.status == 1) {
            self.printData = false;
            Swal.fire({
              icon: "warning",
              title: response.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              self.ReSet();
            }, 2000);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ReSet() {
      let self = this;
      // self.toggleCamera();
      self.tenant = "";
      self.locationID = "";
      self.cardData = [];
      self.prefix = "";
      self.firstName = "";
      self.lastName = "";
      self.sex = "";
      self.idCard = "";
      self.SyncData = false;
      self.printData = false;
      location.reload();
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },
    ChangePageOpenBar(value) {
      let self = this;
      self.LoadingDialog = true;
      self.pageOpenBar = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPageOpenBar,
        search: self.searchOpenBar == null ? "" : self.searchOpenBar,
      };
      axios
        .post(`${self.url}OpenBar/getTransacOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.OpenBarList = response.data.data.listData;
            self.pageCountOpenBar = response.data.data.count;
            self.no_runOpenBar =
              (self.pageOpenBar - 1) * self.itemsPerPageOpenBar;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPageOpenBar(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageOpenBar,
        perpage: value,
        search: self.searchOpenBar == null ? "" : self.searchOpenBar,
      };
      axios
        .post(`${self.url}OpenBar/getTransacOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.OpenBarList = response.data.data.listData;
            self.pageCountOpenBar = response.data.data.count;
            self.no_runOpenBar =
              (self.pageOpenBar - 1) * self.itemsPerPageOpenBar;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePageHandheld(value) {
      let self = this;
      self.LoadingDialog = true;
      self.pageHandheld = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPageHandheld,
        search: self.searchHandheld == null ? "" : self.searchHandheld,
      };
      axios
        .post(`${self.url}OpenBar/getTerminalHandheldOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.HandheldList = response.data.data.listData;
            self.pageCountHandheld = response.data.data.count;
            self.no_runHandheld =
              (self.pageHandheld - 1) * self.itemsPerPageHandheld;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPageHandheld(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.pageHandheld,
        perpage: value,
        search: self.searchHandheld == null ? "" : self.searchHandheld,
      };
      axios
        .post(`${self.url}OpenBar/getTerminalHandheldOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.HandheldList = response.data.data.listData;
            self.pageCountHandheld = response.data.data.count;
            self.no_runHandheld =
              (self.pageHandheld - 1) * self.itemsPerPageHandheld;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    SearchRate(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      // var ampm = hours >= 12 ? "PM" : "AM";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var month = DateData.getMonth() + 1;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        month +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;

      if (
        that.LicensePlate == "" ||
        that.LicensePlate == null ||
        that.terminal == "" ||
        that.terminal == null ||
        that.openBarIssue == 0 ||
        that.openBarIssue == null
      ) {
        return true;
      } else {
        return false;
      }
    },

    addOpenBar() {
      let that = this;
      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      that.LoadingDialog = true;
      that.loader = "loading4";

      let Terminal_name = that.terminalList.find(
        (x) => x.terminal_ID == that.terminal
      ).terminal_Name;
      let Reason = that.openBarIssueList.find(
        (x) => x.issue_ID == that.openBarIssue
      ).issue_Name;

      let temp = {
        terminalId: that.terminal,
        licensePlate: that.LicensePlate,
        reason: Reason,

        reasonID: that.openBarIssue,
        terminalName: Terminal_name,
      };
      axios
        .post(`${that.url}OpenBar/addOpenBar`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });

            that.terminal = "";
            that.LicensePlate = "";
            that.openBarIssue = "";
            that.loader = null;
            that.loading4 = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // that.getRateList();
          that.RateDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          Swal.fire("Error!", error.message);
        });
    },

    GetColor(value) {
      if (value == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ChangeFormatDateNoTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #1d2939;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
body {
  margin: 0;
  overflow: hidden;
}
.background {
  width: 100vw;
  height: 100vh;
  --amount: 20;
}
.background span {
  width: 8vmin;
  height: 8vmin;
  border-radius: 4vmin;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
  animation-iteration-count: infinite;
  animation-duration: 3s;
  top: calc(50% - 4vmin);
  left: 50%;
  transform-origin: -4vmin center;
}
.background span:nth-child(1) {
  background: #35b0ab;
  animation-delay: -0.5s;
  opacity: 0;
}
.background span:nth-child(2) {
  background: #35b0ab;
  animation-delay: -1s;
  opacity: 0;
}
.background span:nth-child(3) {
  background: #226b80;
  animation-delay: -1.5s;
  opacity: 0;
}
.background span:nth-child(4) {
  background: #35b0ab;
  animation-delay: -2s;
  opacity: 0;
}
.background span:nth-child(5) {
  background: #c5f0a4;
  animation-delay: -2.5s;
  opacity: 0;
}
.background span:nth-child(6) {
  background: #226b80;
  animation-delay: -3s;
  opacity: 0;
}
@keyframes move {
  0% {
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}

/* /////// */

body {
  display: flex;
  justify-content: center;
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}
.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 450px;
  height: 337.5px;
  background-color: #fff;
  position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}
.web-camera-container .camera-shoot {
  margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}
.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
