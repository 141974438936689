const environment = {
    // apiUrl: 'https://localhost:44325/api/' , // local
    // apiUrlCarpark: 'https://localhost:44325/api/' , // local
    // apiUrlReader: 'https://10.144.16.3/api/',  // local
    apiUrlReader: 'https://localhost/api/',  // local
    // apiUrlReader: 'https://localhost:44373/api/',  // local
    // apiUrl: 'https://onebangkok-api-dev.dreamy-germain.119-59-118-127.plesk.page/api/'  // VPS Server
    // apiUrl: 'https://10.145.200.226/api/'  // OB dev Server
    // apiUrl: 'https://10.145.204.34/api/'  // OB uat Server
    apiUrl: 'https://www.fineday-api.objective-faraday.119-59-118-127.plesk.page/api/',  // OB prod
    // apiUrl: 'https://10.144.52.130/api/',  // OB prod
    // apiUrl: 'http://10.144.52.130:8080/api/',  // OB prod
    apiUrlCarpark: 'https://10.144.66.34:444/api/',  // OB prod
}
export default environment