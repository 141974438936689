<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Redemption Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="3" class="mt-4">
                  <v-select
                    dense
                    outlined
                    clearable
                    label="Tenant"
                    :items="TenantList"
                    v-model="TenantID"
                    item-text="tenantName"
                    item-value="tenantID"
                    menu-props="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" class="mt-4">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" class="mt-4">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="mt-3">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="SearchDataUser()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn color="green" class="ma-2 white--text" @click="OpenAddDataDialog()">
                    Create
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="UserList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.username }}</td>
                    <td class="header-table-css">
                      {{ ConvertTenant(item.tenantId) }}
                    </td>
                    <td class="header-table-css">
                      {{ ConvertDepartment(item.departmentId) }}
                    </td>
                    <td class="header-table-css">
                      {{ ConvertRedeemType(item.redempType) }}
                    </td>
                    <td style="padding-bottom: 5px" align="center">
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == "Active" ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenEditDataDialog(item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="InActiveRedemptionUser(item)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog"  persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="AddDataDialog" persistent width="870">
      <v-card>
        <v-card-title>
          <p>Add User</p>
          <v-spacer></v-spacer>
          <v-btn @click="CloseAddDataDialog()" icon>
            <img src="@/assets/close.png" width="15" height="15" />
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-5" style="margin-top: -2%">
          <v-col col="12" md="4">
            <p class="Title">Username <span style="color: red">*</span></p>
            <v-text-field dense outlined v-model="AddUsername"></v-text-field>
          </v-col>
          <v-col col="12" md="4">
            <p class="Title">Password <span style="color: red">*</span></p>
            <v-text-field
              dense
              outlined
              type="password"
              id="Addpassword"
              v-model="AddPassword"
            ></v-text-field>
          </v-col>
          <v-col col="12" md="2">
            <v-icon
              v-show="flagAddPassword == true"
              style="margin-top: 45px; margin-left: -15px"
              @click="ToggleAddPassword()"
              >mdi-eye-off</v-icon
            >
            <v-icon
              v-show="flagAddPassword == false"
              style="margin-top: 45px; margin-left: -15px"
              @click="ToggleAddPassword()"
              >mdi-eye</v-icon
            >
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">FirstName</p>
            <v-text-field dense outlined v-model="AddFirstname"></v-text-field>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">LastName</p>
            <v-text-field dense outlined v-model="AddLastname"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">Tenant <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="AddTenant"
              :items="TenantList"
              item-text="tenantName"
              item-value="tenantID"
              menu-props="auto"
            ></v-select>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">Department <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="AddDepartment"
              :items="DepartmentList"
              item-text="departmentName"
              item-value="departmentID"
              menu-props="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">ประเภท Rete <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="AddStampType"
              :items="StampTypeList"
              item-text="redempType_Name"
              item-value="redempType_Id"
              menu-props="auto"
            ></v-select>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">ระดับผู้ใช้</p>
            <v-select
              dense
              outlined
              v-model="AddUserLevel"
              :items="UserStampLevelList"
              item-text="userLevel_Name"
              item-value="userLevel_Id"
              menu-props="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col md="6" col="12">
            <p class="Title">สถานะ <span style="color: red">*</span></p>
            <v-radio-group row v-model="AddStatus">
              <v-radio label="Active" value="1"></v-radio>
              <v-radio label="InActive" value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%; margin-left: 3%"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddRedemptionUser()"
            >Add</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9"
            max-height="35"
            text
            @click="CloseAddDataDialog()"
            >Cancel</v-btn
          >
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="EditDataDialog" persistent width="870">
      <v-card>
        <v-card-title>
          <p>Edit User</p>
          <v-spacer></v-spacer>
          <v-btn @click="CloseEditDataDialog()" icon>
            <img src="@/assets/close.png" width="15" height="15" />
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-5" style="margin-top: -2%">
          <v-col col="12" md="4">
            <p class="Title">Username <span style="color: red">*</span></p>
            <v-text-field dense outlined v-model="EditUsername"></v-text-field>
          </v-col>
          <v-col col="12" md="4">
            <p class="Title">Password <span style="color: red">*</span></p>
            <v-text-field
              dense
              outlined
              type="password"
              id="Editpassword"
              v-model="EditPassword"
            ></v-text-field>
          </v-col>
          <v-col col="12" md="2">
            <v-icon
              v-show="flagEditPassword == true"
              style="margin-top: 45px; margin-left: -15px"
              @click="ToggleEditPassword()"
              >mdi-eye-off</v-icon
            >
            <v-icon
              v-show="flagEditPassword == false"
              style="margin-top: 45px; margin-left: -15px"
              @click="ToggleEditPassword()"
              >mdi-eye</v-icon
            >
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">FirstName</p>
            <v-text-field dense outlined v-model="EditFirstname"></v-text-field>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">LastName</p>
            <v-text-field dense outlined v-model="EditLastname"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">Tenant <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="EditTenant"
              :items="TenantList"
              item-text="tenantName"
              item-value="tenantID"
              menu-props="auto"
            ></v-select>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">Department <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="EditDepartment"
              :items="DepartmentList"
              item-text="departmentName"
              item-value="departmentID"
              menu-props="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col col="12" md="6">
            <p class="Title">ประเภท Rete <span style="color: red">*</span></p>
            <v-select
              dense
              outlined
              v-model="EditStampType"
              :items="StampTypeList"
              item-text="redempType_Name"
              item-value="redempType_Id"
              menu-props="auto"
            ></v-select>
          </v-col>
          <v-col col="12" md="6">
            <p class="Title">ระดับผู้ใช้</p>
            <v-select
              dense
              outlined
              v-model="EditUserLevel"
              :items="UserStampLevelList"
              item-text="userLevel_Name"
              item-value="userLevel_Id"
              menu-props="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-5" style="margin-top: -3%">
          <v-col md="6" col="12">
            <p class="Title">สถานะ <span style="color: red">*</span></p>
            <v-radio-group row v-model="EditStatus">
              <v-radio label="Active" value="1"></v-radio>
              <v-radio label="InActive" value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%; margin-left: 3%"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateRedempUser()"
            >Update</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9"
            max-height="35"
            text
            @click="CloseEditDataDialog()"
            >Cancel</v-btn
          >
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "RedemptionManagement",
  components: {
    AlertComponent,
  },
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      AddDataDialog: false,
      EditDataDialog: false,
      flagPassword: false,
      flagAddPassword: false,
      flagEditPassword: false,

      TenantID: null,
      activeSearch: null,
      UserList: [],
      TenantList: [],
      StampTypeList: [],
      DepartmentList: [],
      UserStampLevelList: [],

      AddUsername: "",
      AddPassword: "",
      AddFirstname: "",
      AddLastname: "",
      AddTenant: null,
      AddDepartment: null,
      AddStampType: null,
      AddUserLevel: null,
      AddStatus: null,

      ID: null,
      EditUsername: "",
      EditPassword: "",
      EditFirstname: "",
      EditLastname: "",
      EditTenant: null,
      EditDepartment: null,
      EditStampType: null,
      EditUserLevel: null,
      EditStatus: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Username",
          align: "left",
          sortable: false,
        },
        {
          text: "Tenant",
          align: "left",
          sortable: false,
        },
        {
          text: "Department",
          align: "left",
          sortable: false,
        },
        {
          text: "RedemptionType",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {},

  async mounted() {
    let self = this;
    await self.getDataRedemptionUser();
    await self.GetDataTenantInRedemption();
    await self.GetDataStampType();
    await self.GetDataDepartment();
    await self.GetDataUserStampLevel(); 
  },

  methods: {
    async getDataRedemptionUser() {
      let self = this;
      let temp = {
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
        search: self.search,
        page: 1,
        perpage: 10,
      };
      self.LoadingDialog = true;
      await axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: value,
        perpage: 10,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.pageCount = response.data.data.count;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.pageCount = response.data.data.count;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    SearchDataUser() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: 10,
        search: self.search,
        tenantID: self.TenantID == null ? 0 : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.page = 1;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    async GetDataTenantInRedemption() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataTenantInRedemption`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TenantList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    async GetDataStampType() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataStampType`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.StampTypeList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    async GetDataDepartment() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataDepartment`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DepartmentList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    async GetDataUserStampLevel() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataUserStampLevel`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserStampLevelList = response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire(error.message);
        });
    },

    OpenEditDataDialog(data) {
      let self = this;
      let temp = {
        id: data.id
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}Redemption/getDataRedemptionUserByID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BindingEditDataUserRedemption(response.data.data);
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          Swal.fire(error.message);
        });
    },

    BindingEditDataUserRedemption(data){
      let self = this;
      self.ID = data.id;
      self.EditUsername = data.username;
      self.EditPassword = data.password;
      self.EditFirstname = data.firstname;
      self.EditLastname = data.lastname;
      self.EditTenant = data.tenantID;
      self.EditDepartment = data.departmentID.toString();
      self.EditStampType = data.redemptionType;
      self.EditUserLevel = data.userLevel;
      self.EditStatus = data.active == true ? "1" : data.active == false ? "2" : null;
      self.EditDataDialog = true;
    },

    ConvertTenant(data) {
      let self = this;
      var result = self.TenantList.filter((x) => x.tenantID == parseInt(data));
      if (result.length > 0) {
        return result[0].tenantName;
      }
      return "";
    },

    ConvertDepartment(data) {
      let self = this;
      var result = self.DepartmentList.filter((x) => x.departmentID == data);
      if (result.length > 0) {
        return result[0].departmentName;
      }
      return "";
    },

    ConvertRedeemType(data) {
      let self = this;
      var result = self.StampTypeList.filter(
        (x) => x.redempType_Id == parseInt(data)
      );
      if (result.length > 0) {
        return result[0].redempType_Name;
      }
      return "";
    },

    GetColor(value) {
      if (value == "Active") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == "Active") {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ToggleAddPassword() {
      let self = this;
      var x = document.getElementById("Addpassword");
      if (x.type === "password") {
        x.type = "text";
        self.flagAddPassword = false;
      } else {
        x.type = "password";
        self.flagAddPassword = true;
      }
    },

    ToggleEditPassword() {
      let self = this;
      var x = document.getElementById("Editpassword");
      if (x.type === "password") {
        x.type = "text";
        self.flagEditPassword = false;
      } else {
        x.type = "password";
        self.flagEditPassword = true;
      }
    },

    OpenAddDataDialog() {
      let self = this;
      self.AddDataDialog = true;
    },

    CloseAddDataDialog() {
      let self = this;
      self.AddUsername = "";
      self.AddPassword = "";
      self.AddFirstname = "";
      self.AddLastname = "";
      self.AddTenant = null;
      self.AddDepartment = null;
      self.AddStampType = null;
      self.AddUserLevel = null;
      self.AddStatus = null;
      self.AddDataDialog = false;
    },

    CloseEditDataDialog() {
      let self = this;
      self.EditUsername = "";
      self.EditPassword = "";
      self.EditFirstname = "";
      self.EditLastname = "";
      self.EditTenant = null;
      self.EditDepartment = null;
      self.EditStampType = null;
      self.EditUserLevel = null;
      self.EditStatus = null;
      self.EditDataDialog = false;
    },

    AddRedemptionUser(){
      let self = this;
      if(self.AddUsername == "" || self.AddPassword == "" || self.AddTenant == null || self.AddDepartment == null
      || self.AddStampType == null || self.AddStatus == null){
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
      else{
        let temp = {
          username: self.AddUsername,
          password: self.AddPassword,
          firstname: self.AddFirstname,
          lastname: self.AddLastname,
          tenantID: self.AddTenant,
          department: self.AddDepartment == null ? null : parseInt(self.AddDepartment),
          stamptype: self.AddStampType,
          userlevel: self.AddUserLevel,
          status: self.AddStatus == "1" ? true : self.AddStatus == "2" ? false : null
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}Redemption/AddRedemptionUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.getDataRedemptionUser();
            self.AddDataDialog = false;
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            Swal.fire(error.message);
          });
      }
    },

    UpdateRedempUser(){
      let self = this;
      if(self.EditUsernameUsername == "" || self.EditPassword == "" || self.EditTenant == null || self.EditDepartment == null
      || self.EditStampType == null || self.EditStatus == null){
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
      else{
        let temp = {
          id: self.ID,
          username: self.EditUsername,
          password: self.EditPassword,
          firstname: self.EditFirstname,
          lastname: self.EditLastname,
          tenantID: self.EditTenant,
          department: self.EditDepartment == null ? null : parseInt(self.EditDepartment),
          stamptype: self.EditStampType,
          userlevel: self.EditUserLevel,
          status: self.EditStatus == "1" ? true : self.EditStatus == "2" ? false : null
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}Redemption/UpdateRedemptionUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.getDataRedemptionUser();
            self.EditDataDialog = false;
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            Swal.fire(error.message);
          });
      }
    },

    InActiveRedemptionUser(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        id: data.id,
      };
      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${self.url}Redemption/InActiveRedemptionUser`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              self.getDataRedemptionUser();
              self.LoadingDialog = false;
            })
            .catch(function (error) {
              self.LoadingDialog = false;
              Swal.fire("Error!", error.message);
            });
            self.LoadingDialog = false;
        }
        self.LoadingDialog = false;
      });
    },
  },
};
</script>
  <style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  /* cursor: pointer; */
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
  